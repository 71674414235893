import { ConfigurationId, FundHeaderLink } from '@types';
import {
  NAV,
  PerformanceDetail,
  Ratings,
  SalesChargeBreakPoint,
} from '../models';
import { ProductType } from './product-type.type';

export interface FundTitleState {
  data?: FundTitleData;
  isTemit?: boolean;
}

export interface FundTitleData {
  identifier?: string;
  fundName?: string;
  productType?: ProductType;
  shareClasses?: ShareClassData[];
  selectedShareClass?: string;
  downloadLink?: string;
  downloadLabel?: string;
  PrimaryShareClasCode?: string;
  fundHeaderLinks?: FundHeaderLink[];
  selectedShareClassData?: ShareClassData; // needed the details to save/remove from favorites
}

export interface ShareClassData {
  shareClassCode: string;
  shareClassName: string;
  link: string;
  shareClassCurrency?: string;
  isActive?: boolean;
}

export interface FundSummaryState {
  data: FundSummaryData;
  calcTypeVisibility: FundSummaryCalcTypeVisibility;
  isSiteIntl: boolean;
  showRatings: boolean;
  shareClassProductType: ConfigurationId;
}

export interface FundSummaryCalcTypeVisibility {
  show3YrAnnualReturn: boolean;
  showNav: boolean;
  showNavYTD: boolean;
  showNavYTDAsOfDate: boolean;
  showDistributionRateAtNav: boolean;
  showPop: boolean;
  showMarketPrice: boolean;
  showGross: boolean;
  showPureGross: boolean;
  showNet: boolean;
  showMoneyFundnav: boolean;
  showLiquidAssetDaily: boolean;
  showLiquidAssetWeekly: boolean;
  showFundLevelMarket: boolean;
  showMasterFundLevelMarket: boolean;
  showMarketToMarketNav: boolean;
  showIntraDayNav: boolean;
  showRedemptionPrice: boolean;
  showPremiumDiscount: boolean;
  showNavChangePercentage: boolean;
  showNavChangeValue: boolean;
  showSalesChargeBreakPoints: boolean;
  showNavYTDUcits: boolean;
}

export interface FundSummaryData {
  nav?: NAV;
  ratings?: Ratings;
  gross?: PerformanceDetail;
  pureGross?: PerformanceDetail;
  net?: PerformanceDetail;
  performance?: {
    monthEnd?: PerformanceDetail;
    quarterEnd?: PerformanceDetail;
  };
  distributionRateAtMarketPrice?: SalesChargeBreakPoint;
  distributionRateAtNav?: SalesChargeBreakPoint;
}
