import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Logger } from '@utils/logger';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { DOCUMENT } from '@angular/common';

const logger = Logger.getLogger('ModalComponent');

enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type ModalCloseReason = 'left' | 'right' | 'closed';

@Component({
  selector: 'ft-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  // BaseModal Params
  @Input() isGrey = false;
  @Input() closeOnClickToBackground = true;
  @Input() title: string;
  @Input() closeLabel: string;
  @Input() showCloseButton = true;
  @Input() headerBottomBorder = true;
  @Input() size = 'normal';

  // Extra Modal Params
  @Input() content: string;
  @Input() rightButtonLabel: string;
  @Input() leftButtonLabel: string;
  @Input() rightButtonType = ButtonType.Primary;
  @Input() leftButtonType = ButtonType.Secondary;
  @Input() autoClose = true; // close modal on click to left or right

  @Output()
  closed: EventEmitter<ModalCloseReason> = new EventEmitter<ModalCloseReason>();

  @ViewChild('modal', { static: false }) modal: BaseModalComponent;

  private initialised = false;
  private showNow = false;
  private autoClosed = false;

  constructor(@Inject(DOCUMENT) private documentReference: Document) {}

  handleRight() {
    if (this.autoClose) {
      this.doAutoClose();
    }
    logger.debug('right');
    this.closed.emit('right');
  }

  handleLeft() {
    if (this.autoClose) {
      this.doAutoClose();
    }
    logger.debug('left');
    this.closed.emit('left');
  }

  ngAfterViewInit() {
    this.initialised = true;
    if (this.showNow) {
      this.modal.show();
    }
  }

  processClosedEvent() {
    if (!this.autoClosed) {
      this.closed.emit('closed');
    }
  }

  show(title?: string, content?: string) {
    if (this.initialised) {
      this.title = title;
      this.content = content;
      this.modal.show();
      // Hack to display modal content after display
      this.documentReference.body.click();
    } else {
      this.showNow = true;
    }
  }

  close() {
    this.modal.close();
  }

  doAutoClose() {
    this.autoClosed = true;
    this.close();
  }
}
