export interface SummaryDetail {
  fundId?: string;
  shareClassCode?: string;
  countryCode?: string;
  languageCode?: string;
  sectionStd?: string;
  section?: string;
  elementNameStd?: string;
  elementName?: string;
  elementValueStd?: string;
  elementValue?: string;
  preelemValueStd?: string;
  preelemValue?: string;
  sortOrder?: string;
  benchmarkOrder?: number;
  dateDriven?: string;
  asOfDate?: string;
  asOfDateStd?: string;
}
