export interface SummaryDetailDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  sectionstd?: string | null;
  section?: string | null;
  elemnamestd?: string | null;
  elemname?: string | null;
  elemvaluestd?: string | null;
  elemvalue?: string | null;
  preelemvaluestd?: string | null;
  preelemvalue?: string | null;
  sortorder?: string | null;
  datedriven?: string | null;
  asofdate?: string | null;
  asofdatestd?: string | null;
  subsortorder?: string | null;
}
