import { Injectable, OnDestroy } from '@angular/core';
import { Channel, PersonalisationPersonalData } from '@types';
import { Logger } from '@utils/logger';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

const logger = Logger.getLogger('LaunchDarklyService');

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
  private client: LaunchDarkly.LDClient;
  private flags: LDFlagSet = null;
  public isInitialised: boolean;

  private flagChange$: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>(
    null
  );

  /**
   * initialize the launch darkly
   */
  public initialize(channelId: Channel, launchDarklyKey: string) {
    const user = {
      kind: 'user',
      channel: channelId,
      anonymous: true,
    } as LaunchDarkly.LDContext;
    this.client = LaunchDarkly.initialize(launchDarklyKey, user);
    this.client
      .waitForInitialization()
      .then(() => {
        this.isInitialised = true;
        this.setFlags();
      })
      .catch((err) => {
        logger.error('launch darkly intialization error : ', err);
      });
  }

  /**
   * update user context after logged in or identified user in the launch darkly
   */
  public updateLaunchDarklyContext(
    userData: PersonalisationPersonalData,
    channelId: Channel,
    launchDarklyKey: string
  ) {
    const user = {
      kind: 'user',
      channel: channelId,
      key: userData.identifiers.globalId,
      type: userData.clientType,
    } as LaunchDarkly.LDContext;
    if (!this.isInitialised) {
      this.isInitialised = true;
      this.client = LaunchDarkly.initialize(launchDarklyKey, user);
    } else {
      this.client.identify(user);
      this.client.on(`change`, (updatedFlags) => {
        for (const key in updatedFlags) {
          if (updatedFlags.hasOwnProperty(key)) {
            this.flags[key] = updatedFlags[key].current;
          }
        }
        this.flagChange$.next(this.flags);
      });
    }
  }

  /**
   * get all the flags
   */
  public getFlags$(): Observable<LDFlagValue> {
    return this.flagChange$.asObservable();
  }

  /**
   * track the button click event in the launch darkly for experimentation
   */
  public trackClick(metricsEventKey: string): void {
    // metrics event key and feature flag key both should be same.
    this.client?.track(metricsEventKey);
  }

  private setFlags() {
    this.flags = this.client.allFlags();
    this.flagChange$.next(this.flags);

    this.client.on(`change`, (updatedFlags) => {
      for (const key in updatedFlags) {
        if (updatedFlags.hasOwnProperty(key)) {
          this.flags[key] = updatedFlags[key].current;
        }
      }
      this.flagChange$.next(this.flags);
    });
  }

  async ngOnDestroy() {
    await this.client?.close();
  }
}
