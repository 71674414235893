import { BaseFilter } from '@products/fund-listing/services/fund-filter.service';

export interface PreFilterItems {
  label: string;
  options: string[];
}

export interface PreFilter {
  filterKey: string;
}

export enum PaginationActionEnum {
  load = 'load',
  previous = 'previous',
  next = 'next',
  activePage = 'activePage',
}

export type PaginationActionType = keyof typeof PaginationActionEnum;
export interface EDSPaginationPageChangedEvent {
  page: number; // current page number
  action: PaginationActionType; // 'load' , 'previous', 'next' and 'activePage'
}

export interface FilterType {
  label: string;
  options?: OptionWithCount[];
  selected?: string[];
  type?: BaseFilter;
}

export interface OptionWithCount {
  value: string;
  count?: number;
  label?: string;
}
