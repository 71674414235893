<div edsGrid>
  <div edsRow>
    <div edsCol columns="{'l': '12', 'm': '8', 's': '4'}">
      <eds-title [title]="title" size="h3" edsSpacing="lg"></eds-title>
      <p>{{ subTitle }}</p>
      <highcharts-chart
        *ngIf="highcharts && chartOptions"
        [Highcharts]="highcharts"
        [options]="chartOptions"
        style="width: 100%; height: 400px; display: block"
      ></highcharts-chart>
      <div
        edsPadding
        edsPaddingAll="md"
        edsPaddingTop="lg"
        edsPaddingBottom="none"
        edsBackground
        theme="white"
        edsFlexbox
        [justifyContent]="(isMobile$ | async) ? 'left' : 'center'"
      >
        <table>
          <tr class="table__tr" *ngIf="(isMobile$ | async) !== true">
            <td
              *ngFor="let legend of legends; let i = index"
              edsPadding
              edsPaddingRight="md"
            >
              <span class="table__legend" edsSpacing
                ><span
                  class="table__legend-box"
                  [style.background]="legend.pointColor"
                ></span
                >{{ legend.label }}</span
              >
            </td>
          </tr>
          <ng-container *ngIf="isMobile$ | async">
            <tr class="table__tr" *ngFor="let legend of legends; let i = index">
              <td edsPadding edsPaddingRight="sm">
                <span class="table__legend" edsSpacing
                  ><span
                    class="table__legend-box"
                    [style.background]="legend.pointColor"
                  ></span
                  >{{ legend.label }}</span
                >
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
