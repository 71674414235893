import { Nominal } from 'simplytyped';

// Internal (Onetis) share class code used for each fund
// although this is just a string, giving it a named (branded) type helps documentation and make typesafe
export type ShareClassCode = Nominal<string, 'ShareClassCode'>;

// Internal (Onetis) share class code used for each fund
// although this is just a string, giving it a named (branded) type helps documentation and make typesafe
export type ShareClassName = Nominal<string, 'ShareClassName'>;

// this is a combination of ONETIS FundId (e.g. '1234Z') and ONETIS ShareClassCode (e.g WE1)
// It identifies a single share class of a fund (e.g. 1234Z-WE1)
export type FundShareClassId = Nominal<string, 'FundShareClassId'>;
