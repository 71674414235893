import {
  ConfigurationCountry,
  ConfigurationLocale,
  ShareClassCode,
} from '@types';
import { Nominal } from 'simplytyped';

// although these are just strings, giving them named (branded) types helps documentation and make typesafe

// individual fund identifier types
export type ADM = Nominal<string, 'ADM'>;
export type AnbimaCode = Nominal<string, 'AnbimaCode'>;
export type APIR = Nominal<string, 'APIR'>;
export type ARSN = Nominal<string, 'ARSN'>;
export type Bloomberg = Nominal<string, 'Bloomberg'>;
export type CUSIP = Nominal<string, 'CUSIP'>;
export type DSC = Nominal<string, 'DSC'>;
export type Frontend = Nominal<string, 'Frontend'>;
export type FundId = Nominal<string, 'FundId'>; // Internal (Onetis) id used for each fund
export type FundNumber = Nominal<string, 'FundNumber'>; // NB: this is different from FundId
export type IntradayNAV = Nominal<string, 'IntradayNAV'>;
export type IOPV = Nominal<string, 'IOPV'>;
export type Iress = Nominal<string, 'Iress'>;
export type IressNAVCode = Nominal<string, 'IressNAVCode'>;
export type ISIN = Nominal<string, 'ISIN'>;
export type LowLoad = Nominal<string, 'LowLoad'>;
export type MEXID = Nominal<string, 'MEXID'>;
export type MLIdentifier = Nominal<string, 'MLIdentifier'>;
export type NASDAQ = Nominal<string, 'NASDAQ'>;
export type ReutersNumber = Nominal<string, 'ReutersNumber'>;
export type RFI = Nominal<string, 'RFI'>;
export type SEDOL = Nominal<string, 'SEDOL'>;
export type TAID = Nominal<string, 'TAID'>;
export type Ticker = Nominal<string, 'Ticker'>;
export type Valoren = Nominal<string, 'Valoren'>;
export type VLN = Nominal<string, 'VLN'>;
export type WKN = Nominal<string, 'WKN'>;

// catch-all fund identifier type
export type FundIdentifier =
  | ADM
  | AnbimaCode
  | APIR
  | ARSN
  | Bloomberg
  | ConfigurationCountry
  | ConfigurationLocale
  | CUSIP
  | DSC
  | Frontend
  | FundId
  | FundNumber
  | IntradayNAV
  | IOPV
  | Iress
  | IressNAVCode
  | ISIN
  | LowLoad
  | MEXID
  | MLIdentifier
  | NASDAQ
  | ReutersNumber
  | RFI
  | SEDOL
  | ShareClassCode
  | TAID
  | Ticker
  | Valoren
  | VLN
  | WKN;
