import { Pipe, PipeTransform } from '@angular/core';
import { formatPercentage } from '@utils/text/number-utils';

@Pipe({
  name: 'ftFormatPercentage',
})
export class FormatPercentagePipe implements PipeTransform {
  transform(val: string | number, display = true): string {
    if (!display) {
      return '';
    }
    // COREWEB-2442 support for german language
    return formatPercentage(val);
  }
}
