import { Nominal } from 'simplytyped';

// individual taxonomy types

//  AKA 'Asset Class Taxonomy'
export enum AssetClass {
  ALTERNATIVES = 'Alternatives',
  EQUITY = 'Equity',
  FIXED_INCOME = 'Fixed Income',
  MONEY_FUNDS = 'Money Funds',
  MULTI_ASSET = 'Multi-Asset',
  // TODO: is 'Tax-Free' a new AssetClass we need to add?
}

// sometimes known as 'Asset Class', but will be deprecated
export enum FundCategory {
  ALTERNATIVES = 'Alternatives',
  BALANCED = 'Balanced',
  EQUITY = 'Equity',
  FIXED_INCOME = 'Fixed Income',
  FUND_OF_FUND = 'Fund of Fund', // found for 529
  MONEY_FUNDS = 'Money Funds',
  MULTI_ASSET = 'Multi-Asset',
}

// although these are just strings, giving them named (branded) types helps documentation and make typesafe
export type ClassGeography = Nominal<string, 'ClassGeography'>;
export type ClassStrategy = Nominal<string, 'ClassStrategy'>;

// catch-all taxonomy type
export type Taxonomy =
  | AssetClass
  | ClassGeography
  | ClassStrategy
  | FundCategory;
