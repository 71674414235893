import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import { SiteConfigService } from '@services/site-config.service';
import { LiteratureDocument } from '@types';

@Component({
  selector: 'ft-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss'],
})
export class CartDialogComponent implements OnDestroy, OnChanges {
  @Input() gridData: LiteratureDocument[] = [];
  @Input() dialogId: string;
  @Input() modalIdEnum: string;
  @Output() dialog = new EventEmitter<string>();
  @Output() deleteDoc = new EventEmitter<string>();
  isMandatoryAvailable = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private cartService: CartHandlerService,
    private siteConfig: SiteConfigService
  ) {}
  ngOnChanges(): void {
    this.checkMandatoryDocAvailablity();
    this.changeDetectorRef.detectChanges();
  }

  checkMandatoryDocAvailablity(): void {
    this.isMandatoryAvailable = false;
    for (const data of this.gridData) {
      if (data.mandatoryMaterial?.length > 0) {
        this.isMandatoryAvailable = true;
        break;
      }
    }
  }
  deleteDocument(docId: string, event: MouseEvent) {
    event.preventDefault();
    this.deleteDoc.emit(docId);
    this.changeDetectorRef.detectChanges();
  }

  close() {
    this.dialog.emit(this.dialogId);
  }

  goToCart() {
    this.cartService.navigateToUrl(
      this.siteConfig.literature?.litOrderCheckoutUrl
    );
    this.dialog.emit(this.dialogId);
  }
  ngOnDestroy(): void {
    this.dialog.emit(this.dialogId);
  }
}
