import { DOCUMENT } from '@angular/common';
import { PipeTransform, Pipe, Inject } from '@angular/core';

@Pipe({ name: 'decodeHtml' })
export class DecodeHtmlPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private documentRef: Document) {}
  transform(value: string): string {
    const tempElement = this.documentRef.createElement('div');
    tempElement.innerHTML = value;
    return tempElement.innerText;
  }
}
