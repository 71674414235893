import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BiographiesDetails,
  BiographySearchItem,
  BrBiographySearchItem,
  InvestmentTeam,
  Product,
  Profile,
} from '@models';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { TranslateService } from '@shared/translate/translate.service';
import {
  ConfigurationId,
  FundManagementState,
  ProductDetailConfiguration,
} from '@types';
import { Logger } from '@utils/logger';
import { forkJoin, observable, Observable, Subject } from 'rxjs';
import fundManagementQuery from '@graphql/overview/fund-management.graphql';
import { SiteConfigService } from '@services/site-config.service';
import { removePTags } from '@utils/text/string-utils';
import { AppStateService } from '@services';

const logger = Logger.getLogger('FundManagementService');

type ProfileRequests = {
  english: Observable<BrBiographySearchItem | BiographySearchItem>;
  localized?: Observable<BrBiographySearchItem>;
};

type ProfileResponse = {
  english: BrBiographySearchItem | BiographySearchItem;
  localized?: BrBiographySearchItem;
};

@Injectable({
  providedIn: 'root',
})
export class FundManagementService {
  private state: FundManagementState = {};
  private configurationName: ConfigurationId;
  private fundManagementStateSubject$: Subject<FundManagementState>;
  private doesBiosFetchFromElastic = false;

  constructor(
    private overviewService: FundOverviewService,
    private translateService: TranslateService,
    private http: HttpClient,
    private siteConfigService: SiteConfigService,
    protected appStateService: AppStateService
  ) {
    this.fundManagementStateSubject$ = new Subject();
    this.appStateService
      .getFetchBiosFromElasticFlag$()
      .subscribe((flag: boolean) => {
        this.doesBiosFetchFromElastic = flag;
      });
  }

  public get fundManagementState(): Subject<FundManagementState> {
    return this.fundManagementStateSubject$;
  }

  /**
   * Call register method of fund overview service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    // Provided dummy fund details to fetch fund overview details.
    this.overviewService
      .register(fundManagementQuery, {
        configurationName: this.getConfigurationName(),
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      })
      .subscribe((product: Product) => {
        if (product) {
          this.mapState(product);
        }
      });
  }

  mapState(product: Product) {
    this.state.data = {
      // NGC-12770: update visibility condition for fund manager bio link.
      showLinkToBios: this.siteConfigService.isShowLinkToBiosStatusValidForFundManagers(
        product.productTaxonomy
      ),
      showYearsOfExperience: this.siteConfigService.isShowYearsOfExperienceValidForFundManagers(
        product.productTaxonomy
      ),
      investmentTeam: product.investmentTeam,
    };
    this.state.data.investmentTeam.forEach((manager) => {
      this.loadProfile(manager);
    });
    this.fundManagementStateSubject$.next(this.state);
  }

  // Interim solution until Fund manager -> funds  linkage can be stored in BR
  loadProfile(manager: InvestmentTeam) {
    if (manager?.employeeId) {
      let url;
      let localizedUrl;
      if (this.doesBiosFetchFromElastic) {
        url = this.appStateService.getBiographiesUrl();
      } else {
        url = `${this.appStateService.getBrBiosDocPath()}?name=Profile&param=${
          manager.employeeId
        }`;
      }

      switch (this.siteConfigService.getPdsLanguage()) {
        case 'pl_PL':
          localizedUrl = `${this.appStateService.getBrBiosDocPath()}?name=PolishProfile&param=${
            manager.employeeId
          }`;
          break;
        case 'es_MX':
          localizedUrl = `${this.appStateService.getBrBiosDocPath()}?name=SpanishProfile&param=${
            manager.employeeId
          }`;
          break;
        default:
        // do nothing
      }

      let reqs: ProfileRequests;
      if (this.doesBiosFetchFromElastic) {
        const headers: HttpHeaders = new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        });
        const body: URLSearchParams = new URLSearchParams();
        body.set('employeeId', manager.employeeId);
        body.set(
          'env',
          this.appStateService.getSearchEnvironment() ||
            this.appStateService.getEnvironment()
        );

        reqs = {
          english: this.http.post(url, body, { headers }),
        };
      } else {
        reqs = {
          english: this.http.get(url),
        };
      }
      if (localizedUrl) {
        reqs.localized = this.http.get(localizedUrl);
      }

      forkJoin(reqs).subscribe((profiles: ProfileResponse) => {
        let profileWithImage: Profile;

        if (profiles.localized?.items?.length) {
          // use localizied version if it exists
          profileWithImage = profiles?.localized?.items.find(
            (profile) => profile.profileImage
          );
          profileWithImage.profileImage.widenAsset = profileWithImage
            ?.profileImage?.widenAsset
            ? JSON.parse(profileWithImage?.profileImage?.widenAsset)
            : '';
        } else {
          profileWithImage = this.getProfileWithImageData(profiles);
        }
        if (profileWithImage) {
          let newContent = '';
          manager.profile = profileWithImage;
          // NGC-12885: Remove p tags from content, so that empty profiles don't open Fund Manager profile
          newContent = removePTags(
            profileWithImage?.content?.content as string
          );
          if (newContent?.length === 0) {
            profileWithImage.content.content = newContent;
          }
          this.fundManagementStateSubject$.next(this.state);
        }
      });
    }
  }

  private getProfileWithImageData(profiles: ProfileResponse): Profile {
    if (this.doesBiosFetchFromElastic) {
      const biosData: BiographiesDetails = ((profiles?.english as unknown) as BiographySearchItem)
        ?.results.response.hits?.hits[0]?._source;
      return this.getProfileFromBiographies(biosData);
    } else {
      const profile: Profile = ((profiles?.english as unknown) as BrBiographySearchItem)?.items.find(
        (profileData) => profileData?.profileImage
      );
      profile.profileImage.widenAsset = profile?.profileImage?.widenAsset
        ? JSON.parse(profile?.profileImage?.widenAsset)
        : '';
      return profile;
    }
  }

  private getProfileFromBiographies(biosData: BiographiesDetails): Profile {
    const profile: Profile = {
      content: {},
      profileImage: {
        widenAsset: '',
      },
    };

    if (biosData?.text1) {
      profile.content.content = `<p> ${biosData?.text1}</p>`;
    }
    if (biosData?.text2) {
      profile.content.content += `<p> ${biosData?.text2}</p>`;
    }
    if (biosData?.text3) {
      profile.content.content += `<p> ${biosData?.text3}</p>`;
    }
    if (biosData?.text4) {
      profile.content.content += `<p> ${biosData?.text4}</p>`;
    }

    profile.location = biosData?.location;
    profile.fullName = biosData?.displayName;
    profile.profileImage.widenAsset = biosData?.webImage;
    return profile;
  }

  /**
   *  helper methof adding suffix to managers name
   * @param investmentManager manager entity
   */
  getManagerTitleSuffix(investmentManager: InvestmentTeam): string {
    if (investmentManager.cfa === 'true') {
      return this.translateService.instant('products.cfa');
    } else if (investmentManager.cpa === 'true') {
      return this.translateService.instant('products.cpa');
    } else if (investmentManager.mba === 'true') {
      return this.translateService.instant('products.mba');
    } else if (investmentManager.phd === 'true') {
      return this.translateService.instant('products.phd');
    } else if (investmentManager.frm === 'true') {
      return this.translateService.instant('products.frm');
    } else {
      return '';
    }
  }

  setConfigurationName(configurationName: ConfigurationId) {
    this.configurationName = configurationName;
  }

  getConfigurationName(): ConfigurationId {
    return this.configurationName;
  }
}
