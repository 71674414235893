import { DropdownItem, InfoItem } from '@frk/eds-components';
import { BarChartData, FundId, ShareClassCode } from '@types';

export interface PremiumDiscountAnalysisPeriod
  extends DropdownItem,
    Omit<BarChartData, 'fundId' | 'chartTitle'> {
  statistics: InfoItem[];
}

export interface PremiumDiscountElevated {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  countryCode?: string;
  languageCode?: string;
  periodId?: number;
  endDate?: string;
  tradingDays?: number;
  eventType?: string;
}
