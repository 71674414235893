import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AdditionalLegacyLink,
  AnalyticsService,
  CountryHub,
  CustomSignInToggleInfo,
  FlyoutMenu,
  HiddenInput,
  ModalService,
  Navigation,
  PrimaryNavigationService,
  ResponsiveService,
  SignInComponentFieldsInfo,
  SignInEvent,
  SignInMenuClickEvent,
  SignInNotificationOptions,
} from '@frk/eds-components';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import {
  getBreakpointType,
  isExternalLink,
  openInNewTab,
  setMenuIcon,
  overwriteTitle,
} from '@marketing/marketing-utils';
import {
  AppStateService,
  GlobalConfigService,
  IUserProfile,
  LanguageSelectorService,
  LoginSource,
  ProfileService,
  RoleSelectorService,
  SegmentService,
  SignInService,
  SiteConfigService,
  EnvConfigService,
  WidenService,
  PersonalisationAPIService,
  WidenFirmLogo,
} from '@services';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import {
  AnalyticsLogin,
  SignInInterface,
} from '@shared/sign-in/sign-in.interface';
import { TranslateService } from '@shared/translate/translate.service';
import {
  CommonConfig,
  CustomHeader,
  GlobalId,
  Segment,
  SegmentId,
  SimplyLinkItem,
  SiteParams,
} from '@types';
import { ANGULAR_HOOK, BREAK, MYFUNDS_HOOK } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { getMenus } from '@utils/pagemodel-utils';
import find from 'lodash/find';
import get from 'lodash/get';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GlobalItem,
  HeaderCards,
  HeaderDocument,
  SignInNavItems,
  SiteNavItem,
} from './header.interfaces';
import { SearchComponent } from '../../../ft-search/search.component';
import { SkiptoHeaderService } from '@services/skipto-header.service';
import { Router } from '@angular/router';
import { ViewModeService } from '@services/view-mode.service';
import { replaceTokens } from '@utils/text/string-utils';
import { LinkService } from '@services/link.service';
import { SiteAlertService } from '@accounts/services/site-alert.service';
import { NotificationDetails } from '@accounts/accounts-alerts/accounts-alerts.interface';
import { DOCUMENT } from '@angular/common';
import { Brand } from 'src/app/types/brand.enum';

const logger = Logger.getLogger('HeaderComponent');

/**
 * Header Component
 */
@Component({
  selector: 'ft-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public isSubmitButtonDisabled = false;

  public globalItems: GlobalItem[] = [];

  /**
   * Site config
   */
  private siteParams: SiteParams;
  private siteGeneral: CommonConfig;

  /**
   * Brand
   */
  public brand: Brand;
  public hasUpliftStyling: boolean;
  public isUpliftFeature: boolean;
  /**
   * Header Logo
   */
  public headerLogo = '/assets/images/logos/FT_logo_pos_RGB@2x.png';
  public mobileHeaderLogo = '';
  public hasWideLogo: boolean;
  public hasLogoLink = true;
  public hasFirmBrandedLogo = false;
  /**
   * Header Logo Alt
   */
  public logoAltText: string;
  public homeUrl: string;
  public cartSize = 0;
  // private isCartEnabled = false; // doesn't appear to be used anymore
  public siteNavData: SiteNavItem[];

  /**
   * current segment data
   */
  private hasMoreThanOneVisibleSegment: boolean;
  private currentSegment: Segment;
  public currentSegmentLabel: string;

  /**
   * Header microsite Logo
   */
  public isMicrosite: boolean;
  private hasTextLogo: boolean;
  public micrositeTextLogo = '';
  public micrositeLogoHref = '';

  // Sign In
  public hasSignInBtn = false;
  public isLogIn = false;
  private profile: IUserProfile;
  private signInForm: FormGroup;
  public signInNavItems: SignInNavItems[] = [];
  public signInComponentContent: SignInComponentFieldsInfo;
  public signInFormAction = '';
  public showLegacySignInOption: boolean;
  public showLegacyContent = true;
  public signInHiddenInputs: HiddenInput[];
  public signInParams: SignInInterface;
  public signedInUserName: string;
  public signedInUserNav: Array<FlyoutMenu> = [];
  public accountsNavName: string;
  public accountsNav: Array<FlyoutMenu> = [];
  /**
   * TODO Flag and params deciding if search needs to be displayed
   * hasSearch will be read from config.
   * TBD
   */
  // Search
  public hasSearch = false;
  public isSearchActive = false;
  @ViewChild(SearchComponent, { static: false })
  searchComponent: SearchComponent;
  // private searchPlaceholder = 'Search Franklin Templeton'; // doesn't appear to be used anymore
  // private searchClearText = 'Clear'; // doesn't appear to be used anymore
  public searchFieldId = 'site-search';

  // Header Style
  public headerStyle: 'basic' | 'standard' | 'basic-with-login';
  public countryHub: CountryHub;
  private showRoleSelectionLink: boolean;
  public hasNavigation = true;

  // HeaderCustomComponent data which overrides Header content from siteParams if present
  public headerContent: CustomHeader;

  public langItems: SimplyLinkItem[] = [];
  // skipTo string
  public skipLinkPath: string;

  public isEditMode = false;
  public isCustomMegaMenu = false;

  public isHandheld$: Observable<boolean>;
  public customSignInToggle: CustomSignInToggleInfo;
  public signInNav: Navigation[];
  public signInNavCustom: Navigation[];
  public signInNotification: SignInNotificationOptions;
  private isSignInSectionSwitch = false;

  /**
   * Constructor
   */
  // prettier-ignore
  constructor( // NOSONAR - disable rule - Maximum allowed Constructor parameters 7. sonarlint(typescript:S107)
    private appStateService: AppStateService,
    private pageConfigService: GlobalConfigService,
    private siteConfigService: SiteConfigService,
    private cartService: CartHandlerService,
    private changeDetection: ChangeDetectorRef,
    private profileService: ProfileService,
    private segmentService: SegmentService,
    private signInService: SignInService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private languageSelectorService: LanguageSelectorService,
    private modalService: ModalService,
    private roleSelectorService: RoleSelectorService,
    private analyticsService: AnalyticsService,
    private skiptoHeaderService: SkiptoHeaderService,
    private router: Router,
    private viewModeService: ViewModeService,
    private envConfigService: EnvConfigService,
    private widenService: WidenService,
    private linkService: LinkService,
    private personalisationService: PersonalisationAPIService,
    private primaryNavService: PrimaryNavigationService,
    private responsiveService: ResponsiveService,
    private siteAlertService: SiteAlertService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {
    super();
    this.isHandheld$ = this.responsiveService.isHandheld$();
    // TODO: for components initialisation code should usually be in ngOnInit() instead of constructor
    this.siteParams = this.pageConfigService.getSiteParams();
    this.siteGeneral = this.pageConfigService.getSiteGeneral();
    this.headerLogo = get(
      this.siteParams,
      'headerLogo',
      '/assets/images/logos/FT_logo_pos_RGB@2x.png'
    );
    this.mobileHeaderLogo = get(
      this.siteParams,
      'mobileHeaderLogo',
      ''
    );
    this.hasWideLogo = get(
      this.siteParams,
      'hasWideLogo',
      false
    );
    this.logoAltText = get(
      this.siteParams,
      'logoAltText',
      'Franklin Templeton Investments'
    );
    this.homeUrl = this.relativeHomeURL(this.appStateService.getHomePageUrl());
    this.isMicrosite = !!this.siteParams?.isMicrosite;
    this.hasTextLogo =
      typeof this.siteParams?.headerMicrositeTextLogo !== 'undefined' &&
      this.siteParams?.headerMicrositeTextLogo !== '';
    if (this.isMicrosite) {
        if (this.hasTextLogo) {
            this.micrositeTextLogo = this.siteParams?.headerMicrositeTextLogo;
        }
        this.micrositeLogoHref =
        typeof this.siteParams?.headerMicrositeLogoHref !== 'undefined' &&
        this.siteParams?.headerMicrositeLogoHref !== ''
          ? this.siteParams?.headerMicrositeLogoHref
          : this.homeUrl;
    }

    //  WDE-3477
    if (
        this.siteParams?.headerMicrositeLogoHref &&
        typeof this.siteParams?.headerMicrositeLogoHref !== 'undefined' &&
        this.siteParams?.headerMicrositeLogoHref !== ''){
        this.homeUrl = this.siteParams?.headerMicrositeLogoHref;
    }

    this.hasSearch = this.siteGeneral?.searchHeader || false;
    this.isEditMode = this.viewModeService.isEditMode();

    // UDS-1196 - Listen for firm logos from firm header logos document
    combineLatest([this.widenService.getFirmLogosFromWiden$(), this.firmId$(), this.isHandheld$])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([firmLogos, parentFirmGlobalID, isHandheld]: [WidenFirmLogo[], GlobalId, boolean]): void => {
      if (firmLogos.length > 0) {
        const firmLogo = firmLogos.find((firm: WidenFirmLogo) => firm.firmRole === parentFirmGlobalID);
        if (firmLogo) {
          this.headerLogo = (isHandheld && firmLogo.mobilLogoForHandheld) ? firmLogo.widenFirmMobileLogoUrl : firmLogo.widenFirmLogoUrl;
          this.mobileHeaderLogo = firmLogo.widenFirmMobileLogoUrl || '';
          this.hasLogoLink = !firmLogo.isLogoNotClicable;
          this.homeUrl = firmLogo.customLogoLink || this.homeUrl;
          this.hasFirmBrandedLogo = true;
        }
        this.changeDetection.detectChanges();
      }
    });

    combineLatest([this.siteAlertService.getSiteAlertsSubject$(), this.firmId$()]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([notifications, parentFirmGlobalID]: [NotificationDetails[], GlobalId]): void => {
        if (notifications?.length > 0) {
          // UDS-1518 - at  this moment we are supporting only one header notification, so the first one will be displayed.
          // Find the first notification that meets the following conditions:
          // 1. It's a firm-specific notification if `parentFirmGlobalID` is available.
          let notificationDetails = notifications.find((notification: NotificationDetails) => {
            const isFirmNotification = parentFirmGlobalID && notification.firms.includes(parentFirmGlobalID);
            return isFirmNotification && notification.isHeaderNotification;
          });
          // 2. If there is no firm-specific notification, then find the first global notification.
          if (!notificationDetails) {
            notificationDetails = notifications.find((notification: NotificationDetails) => {
              return notification.isHeaderNotification && notification.firms.length === 0;
            });
          }
          this.signInNotification = {
              titleText: notificationDetails?.title,
              bodyText: notificationDetails?.notificationLabel,
              CTAText: notificationDetails?.ctaText,
              type: notificationDetails?.notificationType,
              notificationOptions: notificationDetails?.notificationButton,
            };
        }
      });

    // get brand from service 
    this.brand =  this.appStateService.getBrand();      
  }

  /**
   * Retrieves the firm ID as an Observable of type GlobalId
   * The firm ID is obtained from the user profile's firm or the parent firm's global ID.
   * @returns An Observable that emits the firm ID.
   */
  private firmId$ = (): Observable<GlobalId> => {
    const userProfileFirm =
      this.profileService.getUserProfile()?.firm?.parentFirmId ||
      this.profileService.getUserProfile()?.profileInfo?.firm ||
      '';
    const firmIdSubject$ = new BehaviorSubject<GlobalId>(
      userProfileFirm as GlobalId
    );

    this.personalisationService
      .getParentFirmGlobalID$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((parentFirmGlobalId) => {
        firmIdSubject$.next(parentFirmGlobalId);
      });

    return firmIdSubject$.asObservable();
  };

  /**
   * Init
   */
  // prettier-ignore
  public ngOnInit(): void { // NOSONAR - disable rule - Maximum allowed Constructor parameters 7. sonarlint(typescript:S107)
    this.labelIcon = this.isEditMode ? '[L]' : '';

    this.headerContent = this.component?.getModels()?.Header;
    this.initHeaderStyle();
    logger.debug('Header style initialised');

    // role selector link in a header
    this.showRoleSelectionLink = !this.headerStyle?.startsWith('basic');
    this.hasMoreThanOneVisibleSegment =
      this.segmentService.getVisibleSegments()?.length > 1;

    this.currentSegment = this.segmentService.getCurrentSegment();
    this.currentSegmentLabel =
      this.hasMoreThanOneVisibleSegment && this.showRoleSelectionLink
        ? this.currentSegment?.label
        : '';

    // Due to Mega-menu functionality getSiteNavData needs to be initialised once in OnInit
    this.siteNavData = this.getSiteNavData();
    logger.debug('processed siteNavData', this.siteNavData);
    this.hasNavigation = this.siteNavData?.length > 0 || this.globalItems?.length > 0;
    if (this.signInNav.length > 0 || this.signInNavCustom.length > 0) {
      this.setCustomSignInToggle();
    }
    /**
     * Configure column breaks if any
     */
    this.checkForColumnBreak(this.siteNavData);

    if (!this.siteConfigService.hideSignWidget()) {
      this.hasSignInBtn = this.appStateService.hasAuthentication();
    }

    // Enable image cards in mega menu (Putnam)
    this.isCustomMegaMenu = this.currentSegment?.isCustomMegaMenu;

    if (this.isCustomMegaMenu) {
      this.updateHeaderDocInSiteNav();
    }

    /**
     * Set cart
     */
    this.cartService
      .getCartCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((count) => {
        this.cartSize = count;
        this.changeDetection.detectChanges();
      });

    if (this.hasSignInBtn && !this.customSignInToggle) {
    /**
     * Initialise sign-in form
     */
      this.showLegacySignInOption =
      this.siteGeneral?.authenticationLegacy || false;
      if (this.appStateService.isSimplyLoginMethod()) {
        this.signInFormAction = this.signInService.getLoginUrl();
        this.signInHiddenInputs = this.signInService.getHiddenInputs(true);
      }
      this.signInForm = this.signInService.signInFormInit(this.formBuilder);
      this.signInParams = this.getSignInStyle();
      this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
        this.signInParams
      );
      this.signInService
        .getFastTrackRegistration$(this.signInParams, true)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((signIn: SignInInterface) => {
          this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
            signIn
          );
        });
    }
    // listen for accounts unavailable and set error message
    if (this.signInComponentContent) {
      this.profileService
        .isAccountsAvailable$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((message: string) => {
          this.signInComponentContent.errorText = message;
          logger.debug('signin error', message, this.signInComponentContent);
        });
    }
    // end of sign-in code

    // this delays processing until validated user profile has returned
    this.profileService
      .getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IUserProfile): void => {
        logger.debug('profile set in header', profile);
        this.profile = profile;
        // Hide sign in for specific firms
        if (profile?.firm?.hideSignIn){
          this.hasSignInBtn = false;
        }
        // Show/hide login
        if (this.hasSignInBtn) {
          // Load SignIn forms only if sign in is enabled.
          if (this.accountsNav.length === 0) {
            this.loadSignInContentFormLabels();
          }
          if (this.profile.isLoggedIn) {
                this.isLogIn = this.profile.isLoggedIn;
                this.signedInUserName = this.profile.profileInfo?.displayName || `${this.profile.profileInfo?.firstName} ${this.profile.profileInfo?.lastName}`;
                if (
                  !this.appStateService.isSimplyLoginMethod() &&
                  !this.appStateService.isOktaLogin() &&
                  // If bypass parameter does not exist in URL and user is still bypassed we are not able to set these values.
                  !this.profileService.isBypass(true)
                ) {
                  // Adding link to profile dashboard base on profile information
                  if (this.profile.profileInfo?.dashboardUrl) {
                    this.setDashboardUrlForNav(this.profile.profileInfo.dashboardUrl);
                  }
                  // Adding web profile and service-center
                  if (this.signedInUserNav.length > 0) {
                    logger.debug('Additional generated links for signedInUserNav', this.signedInUserNav);
                    // TODO: this is temporary remove from list. Additional requrements will be added in the future.
                    // NOSONAR - disable rule S125 - this is a temporary solution.
                    // this.signedInUserNav.unshift({
                    //   link: this.translateService.instant('signin.profile-label'),
                    //   href:
                    //     this.appStateService.getProfileDomain() +
                    //     '/profile/webprofile',
                    // });
                  } else {
                    // Check the nav Item in the signInNavItems array,
                    // if already exist in the array no need to push
                    const signInNavHrefList = this.signInNavItems?.map((element: SignInNavItems) => element.href);
                    const href = this.appStateService.getProfileDomain() + '/profile/webprofile';
                    if (signInNavHrefList?.indexOf(href) < 0 && this.signedInUserName) {
                      this.signInNavItems.push({
                        text: this.signedInUserName,
                        href,
                        iconType: this.translateService.instant('signin.profile'),
                        class: 'dd-privacy-mask',
                        eventParams: {
                          link_text: '[privacy mask]',
                        },
                      });
                    }
                  }
                }
          }
        }
        // Replacing home URL with partner dashboard URL if configured.
        if (this.profile.profileInfo?.firm) {
          this.homeUrl =
            this.profileService.getPartnerDashboardUrl(
              this.profile.profileInfo?.firm
            ) || this.homeUrl;
        }
        // Hide search for specific firms
        if (profile?.firm?.hideSearch){
          this.hasSearch = false;
        }
        // Hide role selection links for specific firms
        if (profile?.firm?.hideRoleSelectionLinks){
          this.showRoleSelectionLink = false;
          this.currentSegmentLabel = '';
        }



        this.changeDetection.detectChanges();
      });

    /**
     * skipLinkPath to main-content
     */
    this.skipLinkPath = `${this.router.url}#main-content`;
    /**
     * Get language selector for footer
     */
    this.langItems = this.languageSelectorService.getConvertedLanguages();
    if (this.langItems.length > 0) {
      this.hasNavigation = true;
    }

    /**
     * Get country hub for gateway
     */
    this.countryHub = this.getCountryHub();

    this.personalisationService.hasFirmSpecificProducts$().pipe(takeUntil(this.unsubscribe$)).subscribe((isFirmAdvisor: boolean) => {
      // hide role selector for users identified with specific firms
      if (isFirmAdvisor) {
        this.currentSegmentLabel = '';
      }
    });

    // Change Main Menu > Dashboard link after user logged in / tokenised
    combineLatest(
      [this.personalisationService.isIdentified$(),
      this.profileService.getUserProfile$()]
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([identified, profile]) => {

        const dashboadLink: SiteNavItem = this.siteNavData.filter(
          (nav) => nav.hook === 'dashboard'
        )[0];

        const dashboadLinks: SiteNavItem[] = dashboadLink?.child;

        if (identified || profile?.isLoggedIn) {
          if (dashboadLink && !dashboadLinks.length) {
            if (
              this.router.url.indexOf('/service-center') > -1 ||
              this.router.url.indexOf('/my-products-performance#held') > -1
            ) {
              this.primaryNavService.setActivePrimaryNav(dashboadLink.link);
            }
            dashboadLink.link = '/service-center';
            return;
          }

          dashboadLinks?.forEach((item: SiteNavItem) => {
            switch (item.hook) {
              case 'PRC':
                // This will identify 'My Dashboard' menu in MM and make it active
                if (this.router.url.indexOf('/service-center') > -1) {
                  this.primaryNavService.setActivePrimaryNav(item.link);
                }
                item.link = '/service-center';
                break;
              case 'PRC-Perf':
                // This will identify 'My Dashboard' menu in MM and make it active
                if (
                  this.router.url.indexOf('/my-products-performance#held') >
                  -1
                ) {
                  this.primaryNavService.setActivePrimaryNav(item.link);
                }
                item.link = '/my-products-performance#held';
                break;
              default:
                logger.warn(`Unknown hook: ${item.hook}`);
            }
          });
        } else {
          /**
           * Manipulate My Dahsboard childern links to sign in using correct redirect URL
           * Dirty code - fix it in DEE project later
           */
          setTimeout(() => {
            this.responsiveService
              .isHandheld$()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((isHandheld: boolean) => {
                if (isHandheld) {
                  const dashboardElm = this.documentRef.querySelectorAll(
                    '[aria-label="My Dashboard"]'
                  )[0];
                  this.ammendMyDashboardLinks(dashboardElm, true);
                } else {
                  const dashboardElm =
                    this.documentRef.getElementById('My Dashboard') ||
                    this.documentRef.querySelector('[title="My Dashboard"]');
                  this.ammendMyDashboardLinks(dashboardElm);
                }
              });
          }, 2000);
        }
      });

    // check for FRK_DEE branding 
    this.checkBrand();
  }

  // update based on brand check
  checkBrand() {
    const themeName = this.getThemeNameFromStylesheet();
    const upliftThemes = [
      'frk-dee',
      'frk-sky-tc-zh',
      'frk-sky-ko',
      'frk-sky-lat-el-cyr',
      'frk-sky-sc-zh',
    ];

    this.hasUpliftStyling =
      this.brand === Brand.FRK_DEE || upliftThemes.includes(themeName);
    // apply new feature if uplift styling is true
    this.isUpliftFeature = this.hasUpliftStyling;
  }

  // check the stylesheet for the applied theme and then use the themename to apply in checkBrand()
  getThemeNameFromStylesheet() {
    const links = Array.from(
      document.querySelectorAll('link[rel="stylesheet"]')
    ) as HTMLLinkElement[];
    for (const link of links) {
      const url = link.href;
      if (url.includes('/assets/css/')) {
        const match = url.match(/\/assets\/css\/(.*?).theme.css/);
        return match ? match[1] : null;
      }
    }
    return null;
  }

  /**
   * Manupulate links for My Dashboard children links in MM
   * @param dashboardElm any
   * @param isHandheld boolean
   */
  private ammendMyDashboardLinks(dashboardElm, isHandheld = false): void {
    const selector = isHandheld ? 'a.link--basic' : 'a.header__mega-menu-link';
    const children = dashboardElm?.nextElementSibling?.querySelectorAll(
      selector
    );

    /**
     * When there is NO sub links under My dashboard MM item
     */
    if (!children) {
      dashboardElm?.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        this.signInService.submitAuth0('', '/service-center');
      });
      return;
    }

    children?.forEach((element) => {
      (element as HTMLAnchorElement).addEventListener(
        'click',
        (event: MouseEvent) => {
          event.preventDefault();
          const targetText = isHandheld
            ? (event.currentTarget as any).innerHTML.trim()
            : (event.currentTarget as any)
                .getElementsByClassName('header__mega-menu-title-name')[0]
                .innerHTML.trim();

          if (targetText === 'My Performance') {
            this.signInService.submitAuth0('', '/my-products-performance#held');
          } else {
            this.signInService.submitAuth0('', '/service-center');
          }
        }
      );
    });
  }

  /**
   * Set dashboard URL for navigation items.
   * @param dashboardUrl - dashboard URL
   */
  private setDashboardUrlForNav(dashboardUrl: string): void {
    if (this.signInNavItems.length > 0 && this.accountsNav.length === 0) {
      this.signInNavItems[0].href = dashboardUrl;
    } else {
      this.accountsNav.forEach((navItem: FlyoutMenu, navIndex: number) => {
        if (navItem?.href?.includes(this.appStateService.getAccountsDomain())) {
          this.accountsNav[navIndex].type = this.translateService.instant(
            'signin.lock'
          );
          this.accountsNav[navIndex].href = dashboardUrl;
        }
      });
    }
  }

  private setCustomSignInToggle(): void {
    let signInSection = null;
    let customSection = null;
    if (this.signInNav.length > 0) {
      signInSection = {
        titleLabel: this.translateService.instant('signin.ftAdvisorWebsite'),
        signInLabel: this.translateService.instant('signin.signIn'),
        additionalLinks: this.signInNav,
      };
    }
    if (this.signInNavCustom.length > 0) {
      customSection = {
        titleLabel: signInSection
          ? this.translateService.instant('signin.clientAccounts')
          : null,
        additionalLinks: this.signInNavCustom,
      };
    }
    this.customSignInToggle = {
      signInSection,
      customSection,
      switchSections: this.isSignInSectionSwitch,
    };
  }

  /**
   * Get country hub for gateway
   */
  private getCountryHub(): CountryHub {
    const channelParams = this.page.getChannelParameters();
    return {
      countryHubLink: this.siteParams?.countryHubLink,
      siteName:
        channelParams && channelParams['Site Name']
          ? channelParams['Site Name']
          : '',
    };
  }

  /**
   * Note: this isn't run until profileService returns a validated profile
   */
  private initHeaderStyle(): void {
    const pageMetadata = this.page.getComponent()?.getModels()?.pageData;

    // Header style
    this.headerStyle = pageMetadata?.headerStyle;
  }

  private getSignInStyle(): SignInInterface {
    return {
      legacy: false,
      signOut: true,
      skin: 'basic',
      showLegacyContent: this.showLegacySignInOption,
    };
  }

  private loadSignInContentFormLabels(): void {
    if (
      !this.appStateService.isSimplyLoginMethod() &&
      !this.appStateService.isOktaLogin() &&
      !this.appStateService.isAuth0Login() &&
      !this.profileService.isBypass(true) &&
      !this.profile.firm.hideAccountAccessMenu
    ) {
      this.signInNavItems = [
        {
          text: this.translateService.instant('signin.accountDashboard'),
          href: '',
          iconType: this.translateService.instant('signin.lock'),
        },
      ];
    }
  }

  /**
   * Get label for language dropdown
   */
  // TODO: no functions in templates. This will re-run every time change detection happens
  get languageLabel(): string {
    const channelLang = this.languageSelectorService.getChannelLanguageLabel(
      this.page
    );
    return this.labelIcon + channelLang;
  }

  /**
   * Get Navigational menu for header
   */
  getSiteNavData(): SiteNavItem[] {
    this.menuItem = getMenus(this.page, this.component);
    const siteNavItems: MenuItem[] = get(this, 'menuItem.siteMenuItems', []);
    logger.debug('getSiteNavData', siteNavItems);

    /**
     * Get global Links for footer
     */
    const globalItems = find(this.menuItem?.siteMenuItems, {
      name: 'Utility Navigation',
    });
    this.globalItems = this.convertGlobal(
      get(globalItems, 'childMenuItems', [])
    );
    // Additional menu items for signed in users
    this.signedInUserNav = this.mapUtilityFlyoutNavItems('signedInUserNav');
    // Additional menu items for accounts
    this.accountsNav = this.mapUtilityFlyoutNavItems('Accounts');
    // Additional menu items for sign in button dropdown
    this.signInNav = this.mapUtilityFlyoutNavItems('signInSection');
    this.signInNavCustom = this.mapUtilityFlyoutNavItems('customSection');

    return this.convertNavItems(siteNavItems);
  }

  /**
   * Mapping Service Center Navigation if exists
   */
  private mapUtilityFlyoutNavItems(menuItemName: string): Array<Navigation> {
    const siteMenuItems: MenuItem[] = this.menuItem?.siteMenuItems || [];
    const foundItem = this.findMenuItem(siteMenuItems, menuItemName);
    if (!foundItem?.childMenuItems || foundItem.childMenuItems.length === 0) {
      return [];
    }
    // User will be abble to switch sections in BR by setting 'swapSections' Angular hook in signInNav menu.
    if (this.getHook(foundItem, ANGULAR_HOOK) === 'swapSections') {
      this.isSignInSectionSwitch = true;
    }
    return foundItem.childMenuItems.map((menuItem: MenuItem) => {
      return {
        link: menuItem?.name,
        href: menuItem?.links.site?.href
          ? replaceTokens(
              menuItem.links.site.href,
              this.envConfigService.getEnvConfig()
            )
          : '',
        type: '',
        hrefExternal:
          menuItem.parameters?.[
            'Leaving Site (True or False)'
          ].toLowerCase() === 'true',
        newWindow:
          menuItem.parameters?.[
            'Open in New Tab (True or False)'
          ].toLowerCase() === 'true',
        hook: this.getHook(menuItem, ANGULAR_HOOK),
      };
    });
  }

  /**
   * Find menu item by name in whole menu tree
   * @param menuItems - MenuItem array
   * @param menuItemName - name of the menu item to find
   * @returns - found MenuItem object or undefined
   */
  private findMenuItem(
    menuItems: MenuItem[],
    menuItemName: string
  ): MenuItem | undefined {
    for (const item of menuItems) {
      if (item.name === menuItemName) {
        return item;
      }
      if (item.childMenuItems) {
        const found = this.findMenuItem(item.childMenuItems, menuItemName);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  /**
   * Converting BR menu item to EDS GlobalItem interface
   * @param globalItems - BR array to convert
   */
  private convertGlobal(globalItems: Array<MenuItem>): GlobalItem[] {
    if (!globalItems) {
      return [];
    }
    const globalItemsEds = [];
    globalItems.forEach((item: MenuItem) => {
      const hook: string = this.getHook(item, ANGULAR_HOOK);
      const globalItem: GlobalItem = {
        text: item.name,
        href: get(item, 'links.site.href', ''),
        hrefExternal: isExternalLink(item),
        newWindow: openInNewTab(item),
        breakpointType: getBreakpointType(item),
        isCart: this.isChart(item.name),
        iconType: setMenuIcon(item),
        hook,
      };
      globalItemsEds.push(globalItem);
    });
    return globalItemsEds;
  }

  /**
   * Check if menu item contains cart
   * @param name - menu item name
   */
  // TODO: should this be called isCart()?
  private isChart(name: string): boolean {
    return name.toLowerCase().includes('cart');
  }

  /**
   * Converting BR menu item to EDS NavItem interface
   * @param siteNavItems - BR array to convert
   */
  private convertNavItems(siteNavItems: Array<MenuItem>): SiteNavItem[] {
    if (!siteNavItems) {
      return [];
    }
    const siteNavItemsEds: SiteNavItem[] = [];
    const mainNavItems: MenuItem = siteNavItems.find(
      (mainNav) => mainNav.name === 'Main Navigation'
    );
    siteNavItems = mainNavItems?.childMenuItems
      ? mainNavItems.childMenuItems
      : siteNavItems;
    siteNavItems.forEach((item: MenuItem) => {
      if (item.name !== 'Main Navigation') {
        siteNavItemsEds.push(this.navMenuItems(item));
      }
    });
    return siteNavItemsEds;
  }

  /**
   * Returns object for Nav menu
   * @param item - nav item
   */
  private navMenuItems(item: MenuItem): SiteNavItem {
    let link: string = get(item, 'links.site.href', '');
    const hook: string = this.getHook(item, ANGULAR_HOOK);
    const overwriteTitleText = overwriteTitle(item);
    if (hook === MYFUNDS_HOOK) {
      link = this.processMyFunds(link);
    }
    return {
      id: item.name,
      name: item.name,
      active: false,
      link,
      hook,
      hrefExternal: isExternalLink(item),
      breakpointType: getBreakpointType(item),
      newWindow: openInNewTab(item),
      overWriteTitle: overwriteTitleText.length
        ? overwriteTitleText
        : item.name,

      child:
        item.childMenuItems.length > 0
          ? this.convertNavItems(item.childMenuItems)
          : [],
    };
  }

  /**
   * Will loop through mega menu links and determine if any of them contains any column breaks.
   * @param siteNavItem Site Navigation mega menu headers
   */
  private checkForColumnBreak(siteNavItem: SiteNavItem[]): void {
    for (const headerItem of siteNavItem) {
      for (const mainLinkItem of headerItem?.child) {
        if (mainLinkItem.hook === BREAK) {
          headerItem.hasColumnBreaks = true;
          mainLinkItem.isColumnBreak = true;
        }
        for (const subLinkItem of mainLinkItem?.child) {
          if (subLinkItem.hook === BREAK) {
            headerItem.hasColumnBreaks = true;
            subLinkItem.isColumnBreak = true;
          }
        }
      }
    }
  }

  /**
   * used to extract optional metadata for link
   */
  private getHook(item: MenuItem, paramName: string): string | undefined {
    const paramNames: string[] = item.properties?.['hst:parameternames'] || [];
    const paramIndex: number = paramNames.indexOf(paramName);
    if (paramIndex > -1) {
      return item.properties?.['hst:parametervalues'][paramIndex];
    }
  }

  /**
   * Intercepts MyFunds link and redirects to portal if user is logged in
   */
  private processMyFunds(link: string): string {
    let newLink: string = link;
    if (
      this.profile?.isLoggedIn &&
      [LoginSource.OAUTH, LoginSource.DEBUG].includes(this.profile?.loginSource)
    ) {
      switch (this.segmentService.getCurrentSegmentId(true)) {
        case SegmentId.INVESTOR:
          newLink = `${this.appStateService.getMyFundsDomain()}/InternationalInvestorPortal/dashboard`;
          break;
        case SegmentId.DISTRIBUTOR: // fall through
        case SegmentId.FINANCIAL_PROFESSIONALS:
          newLink = `${this.appStateService.getMyFundsDomain()}/InternationalAdvisorPortal/dashboard`;
          break;
        // NOTE: No default case. Only changes url if in specific roles
      }
    }
    return newLink;
  }

  /**
   * Rewrites absolute home page url to relative
   * @param url - string to check
   */
  private relativeHomeURL(url: string): string {
    // Logic moved to service.
    return this.appStateService.relativeHomeURL(url);
  }

  /**
   * opens role selector modal
   */
  public showRoleSelectorDialog(): void {
    this.analyticsService.trackEvent({
      event: 'lightbox_display',
      action: 'role_select',
      label: this.currentSegmentLabel,
    });

    this.analyticsService.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'role_select',
        heading: this.currentSegmentLabel,
      },
    });

    this.utilityNavTracking(
      this.currentSegmentLabel,
      this.analyticsService.getLinkType('')
    );

    this.roleSelectorService.openRoleSelectorModal();
  }

  /**
   * Utility Navigation Tracking
   * @param linkText - string
   * @param linkType - string
   */
  private utilityNavTracking(
    linkText: string,
    linkType: string,
    category = ''
  ): void {
    this.analyticsService.trackEvent({
      event: 'utility_navigation',
      detailed_event: 'Utility Navigation',
      link_url: '',
      link_text: linkText,
      event_data: {
        category,
        link_id: '',
        link_text: linkText,
        link_type: linkType,
        link_url: '',
      },
    });
  }

  public onSubmit(signIn?: SignInEvent) {
    logger.debug('SignInEvent', signIn);
    const submitObject: AnalyticsLogin = {
      signInForm: this.signInForm,
      analyticsKey: 'Header',
    };
    this.utilityNavTracking('Sign In', 'SignIn', 'Sign In/Register');
    if (this.siteConfigService.hideLoginForm()) {
      this.signInService.onSubmit(submitObject);
    } else {
      this.signInForm.value.userId = signIn.userName;
      this.signInForm.value.password = signIn.password;
      // Commented now according to NGC-8586
      // this.signInForm.value.rememberMe = signIn.rememberMe ? REMEMBER_ME_YES : REMEMBER_ME_NO;
      if (!signIn.userName && !signIn.password) {
        this.signInComponentContent.userName.invalid = true;
        this.signInComponentContent.password.invalid = true;
      } else if (!signIn.userName && signIn.password) {
        this.signInComponentContent.userName.invalid = true;
        this.signInComponentContent.password.invalid = false;
      } else if (signIn.userName && !signIn.password) {
        this.signInComponentContent.userName.invalid = false;
        this.signInComponentContent.password.invalid = true;
      } else {
        this.signInComponentContent.userName.invalid = false;
        this.signInComponentContent.password.invalid = false;
        this.isSubmitButtonDisabled = true;
        this.signInService.onSubmit(submitObject);
      }
    }
  }

  public signOut(): void {
    logger.debug('sign out');
    if (this.cartSize > 0) {
      this.modalService.open('confirmationModal', {
        modalId: 'cart-confirmation-modal',
        type: 'simple',
        eventName: 'Cart confirmation',
        eventAction: 'Click',
      });
    } else {
      this.signInService.signOut();
    }
  }

  public onSignInMenuClick(event: SignInMenuClickEvent) {
    // Placeholder for BR menu 'Angular Hook' functionality.
    logger.debug('SignIn Menu Click Event', event);
    if (event.hook === 'register') {
      this.utilityNavTracking(
        'Register or learn more',
        event.hook,
        'Sign In/Register'
      );
      this.signInService.submitAuth0('signup');
    }
  }

  public setSearchButton(searchActive: boolean): void {
    this.isSearchActive = searchActive;
  }

  // clear search history on close search.
  public clearSearchResults(): void {
    this.searchComponent.closeSearch();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.signInService.trackLogin(event);
  }

  public onSkipToEvent(value: boolean) {
    // Get the current value of the boolean from the service
    this.skiptoHeaderService.setSkipToHeaderBoolean(value);
  }

  /**
   * redirect to auth0 signup screen
   */
  public fastTrackRegistration() {
    this.signInService.submitAuth0('signup');
  }

  /**
   * Trigger legacy button click
   * @param linkEvent - AdditionalLegacyLink
   */
  public legacyBtnclick(linkEvent: AdditionalLegacyLink): void {
    this.signInService.openLegacyLink(linkEvent);
  }

  /**
   * Updates the navigation data in the site navigation with information
   * from the header document.
   *
   * NOTE: For now, there is only one image card for each main nav
   * TODO: update logic to support multiple cards
   */
  private updateHeaderDocInSiteNav(): void {
    const doc = this.component?.getParameters()?.document;
    const headerDocument =
      doc && this.page?.getContent(doc)?.getData<HeaderDocument>();
    if (this.siteNavData && headerDocument?.headerCards) {
      this.siteNavData.forEach((nav: SiteNavItem, index) => {
        const headerCards = headerDocument.headerCards[index];

        if (headerCards) {
          this.updateNavWithHeaderCard(nav, headerCards);
        }
      });
    }
  }

  /**
   * Updates a navigation item with data from a header card.
   * @param nav - The navigation item to be updated.
   * @param headerCards - The header card containing data to update the navigation item.
   */
  private updateNavWithHeaderCard(
    nav: SiteNavItem,
    headerCards: HeaderCards
  ): void {
    const { cards } = headerCards;
    const cardLink = cards?.link?.linkCollection?.[0];
    const footerLink = cards?.footerLink?.linkCollection?.[0];

    nav.sectionContent = cards?.content;
    nav.cardImageUrl = this.widenService.getWidenAssetUrl(
      cards?.image?.widenAsset
    );
    nav.cardLinkName = cardLink?.displayText;
    nav.cardUrl = this.linkService.getCTALink(
      this.page,
      cardLink,
      this.appStateService?.getHomePageUrl()
    );
    nav.customHtml = cards?.customHtml;
    nav.footerLinkUrl = this.linkService.getCTALink(
      this.page,
      footerLink,
      this.appStateService?.getHomePageUrl()
    );
    nav.footerLinkName = footerLink?.displayText;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
