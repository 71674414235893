<span *ngIf="isPopulated && tooltipId">
  <span
    *ngIf="debugLabels"
    [title]="tooltipId + '-tooltip'"
    class="caveat-debug"
  >
    &nbsp;[T]
  </span>
  <span *ngIf="hasInfo" [innerHTML]="tooltipLabel"></span>
</span>
