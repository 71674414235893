import {
  ConfigurationCountry,
  ConfigurationLocale,
  SEDOL,
  FundId,
  Bloomberg,
  Ticker,
  ShareClassCode,
} from '@types';

export interface Exchange {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  ticker?: Ticker;
  exchangeName?: string;
  tradeCurrency?: string;
  bloomberg?: Bloomberg;
  reuters?: string;
  sedol?: SEDOL;
  countryCode?: ConfigurationCountry;
  languageCode?: ConfigurationLocale;
}
