import { Injectable } from '@angular/core';
import { Observer, Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  /**
   * Valid Script array
   */
  private scripts: ScriptModel[] = [
    { name: 'Marketo', src: '/assets/js/marketo/forms2.min.js', loaded: false },
  ];

  /**
   * Load Script
   * @param script - script model object
   */
  public load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      // Check existing scripts on page
      const existingScript = this.scripts.find((s) => s.name === script.name);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error("Couldn't load script " + script.src);
        };

        // Append script to body
        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}

export interface ScriptModel {
  name: string;
  src: string;
  loaded?: boolean;
}
