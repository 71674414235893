import { Component, Input } from '@angular/core';

// TODO see web-platform
@Component({
  selector: 'ft-product-tooltip',
  templateUrl: './product-tooltip.component.html',
  styleUrls: ['./product-tooltip.component.scss'],
})
export class ProductTooltipComponent {
  isPopulated = false;
  debugLabels = false;
  tooltipLabel: string;

  @Input() tooltipId = false;
  @Input() hasInfo = true;
}
