export interface Yields {
  secYieldThrtyDay?: string;
  secYieldThrtyDayWoWaiver?: string;
  stdYieldThirtyDayAsofDate?: string;
  currentYield7Day?: string;
  currentYield7DayWithoutWaiver?: string;
  effectiveYield7Day?: string;
  effectiveYield7DayWithoutWaiver?: string;
  taxableEquivalentYield?: string;
  taxableEquivalentYieldWithoutWaiver?: string;
  dividendYield?: string;
  asOfDate?: string;
}
