export type ProfileImage = {
  image?: any;
  widenAsset: string | any;
};

export type Content = {
  content?: any;
  value?: any;
};

export type Profile = {
  profileImage?: ProfileImage;
  fullName?: string;
  designations?: string;
  teamOrSim?: string;
  timeInfund?: string;
  tenure?: string;
  location?: string;
  content?: Content;
};

export type BiographiesDetails = {
  action?: string;
  environment?: string;
  title?: string;
  relativePath?: string;
  documentPath?: string;
  documentName?: string;
  lastModificationDate?: number;
  uuid?: string;
  locale?: string;
  bioType?: string;
  businessEntity?: string;
  displayName?: string;
  employeeId?: string;
  firstName?: string;
  investmentGroup?: string;
  investmentTeam1?: string;
  investmentTeam2?: string;
  job?: string;
  jobTitle1?: string;
  jobTitle2?: string;
  lastName?: string;
  legacySource?: string;
  legacySourceId?: string;
  location?: string;
  region?: string;
  status?: string;
  text1?: string;
  text2?: string;
  text3?: string;
  text4?: string;
  webImage?: string;
  cardImage?: string;
  printImage?: string;
  printImageAlt?: string;
};

export interface InvestmentTeam {
  fundId?: string;
  employeeId?: string;
  countryCode?: string;
  languageCode?: string;
  managerName?: string;
  managerNameWithSuffix?: string;
  managedSinceString?: string;
  managerOrder?: number;
  startDateFundManagement?: string;
  startDateFundManagementStd?: string;
  managerCity?: string;
  managerDesignation?: string;
  yearsOfService?: string;
  yearsInIndustry?: string;
  yearsOfServiceEffectiveDate?: string;
  cfa?: string;
  mba?: string;
  phd?: string;
  cpa?: string;
  frm?: string;
  profile?: Profile;
}

export interface BiographySearchItem {
  results: {
    response: {
      hits: Hits;
    };
  };
}

interface Hits {
  hits: Hit[];
}

interface Hit {
  _source: BiographiesDetails;
}

export interface BrBiographySearchItem {
  items?: Item[];
}

interface Item {
  cardImage?: ProfileImage;
  overview?: string;
  businessGroupTeam?: string;
  fullName?: string;
  location?: string;
  profileImage?: ProfileImage;
  tenure?: string;
  content?: Content;
}
