// these are the brand themes that EDS supports.
// add another option when EDS adds abother brand
// the site's brand is specific in the it's channel json file
export enum Brand {
  CLEARBRIDGE = 'clearbridge',
  CPREIF = 'cpreif',
  FRK = 'frk',
  EP = 'ep',
  NJBEST = 'njbest',
  MARTIN_CURRIE = 'martin-currie',
  BRANDYWINE_GLOBAL = 'brandywine-global',
  FRK20 = 'frk-2.0',
  FRK_DEE = 'frk-dee',
  FRK_SKY = 'frk-sky',
  FRK_ENERGY = 'frk-energy',
  FRK_SUNRISE = 'frk-sunrise',
}
