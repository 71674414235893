import { LiteratureDocument } from './model/literature-document';

export interface OrderHistoryDetail {
  orderNumber?: string;
  orderDate?: string;
  orderStatus?: string;
  firstName?: string;
  lastName?: string;
  workPhone?: string;
  overallQuantity?: number;
  titles?: number;
  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingAddress3?: string;
  city?: string;
  zip?: string;
  countryCode?: string;
  deliveryDetails?: string;
  document?: OrderHistoryDocument[];
}

export interface OrderHistoryDocument extends LiteratureDocument {
  itemDetailLink?: string;
}

export interface FundDetail {
  literatureCode?: string;
  status?: string;
  quantity?: number;
  internetDescription?: string;
  literaturePath?: string;
  literatureType?: string;
  mandatoryMaterials?: string;
  internetTitle?: string;
}

export interface OrderHistoryList {
  orderHistDetail: OrderHistDetail[];
}
export interface OrderHistDetail {
  orderNumber: string;
  orderDate: string;
  orderSource: string;
  orderStatus: string;
  overallQuantity: number;
  titles: number;
}
