import { ISODate } from '@frk/eds-components';

// TODO rename as LiteratureDataDTO as this describes data from external api
export interface LiteratureData {
  hasFundLiteratures?: string | null;
  hasFundDocuments?: string | null;
  document?: (LiteratureDocumentData | null)[] | null;
}

// TODO: there may be more types to add in future
export enum LiteratureDocType {
  ANNUAL_REPORT = 'Annual Report',
  ATTRIBUTION = 'Attribution',
  BROCHURE = 'Brochure',
  CAPITAL_GAINS_INDICATIONS = 'Capital Gains Indications',
  DISCLOSURE = 'Disclosure',
  DISTRIBUTION_REPORT = 'Distribution Report',
  FACT_SHEET = 'Factsheet',
  FISCAL_Q1_HOLDINGS = 'Fiscal Q1 Holdings',
  FISCAL_Q3_HOLDINGS = 'Fiscal Q3 Holdings',
  FLYER = 'Flyer',
  FUND_COMMENTARY = 'Product Commentary',
  INVESTOR_GUIDE = 'Investor Guide',
  INVITATION = 'Invitation',
  KIID = 'Key Investor Information Document',
  KID = 'Key Information Document',
  PORTFOLIO_HOLDINGS = 'Portfolio Holdings',
  INVESTOR_BROCHURE = 'INVESTOR-BROCHURE',
  PRESENTATION = 'Presentation',
  PRODUCT_PITCHBOOK = 'Pitchbook',
  PRODUCT_PROFILE = 'Product Profile',
  PROSPECTUS = 'Prospectus',
  SEMI_ANNUAL_REPORT = 'Semiannual Report',
  SNAPSHOT = 'Snapshot',
  STATEMENT_OF_ADDITIONAL_INFORMATION = 'Statement Of Additional Information',
  ANNUAL_FINANCIAL_STATEMENTS_AND_OTHER_INFORMATION = 'Annual Financial Statements And Other Information',
  SEMIANNUAL_FINANCIAL_STATEMENTS_AND_OTHER_INFORMATION = 'Semiannual Financial Statements And Other Information',
  SUMMARY_PROSPECTUS = 'Summary Prospectus',
  TRADE_RATIONALE = 'Trade Rationale',
  WHITEPAPER = 'Whitepaper',
  ARTICLES = 'ARTICLES',
  PRESS_RELEASE = 'Press Release',
  FOREIGN_TAX_CREDIT_INFORMATION = 'Foreign Tax Credit Information',
  REGULATORY_TAX_INFORMATION = 'Regulatory Tax Information',
  TAX_INFORMATION = 'Tax Information',
  FINRA_LETTER = 'FINRA Letter',
  FLASH_REPORT = 'Flash Report',
  LITERATURE_print = 'Literature Print',
  MINIBOOK = 'Minibook',
  SALES_TOOLS = 'Sales Tools',
  FUND_FACTS = 'Fund Facts',
}

export enum LiteraturePageType {
  REGULATORY_FUND_DOCUMENTS = 'regulatory-fund-documents',
  REGULATORY_FUND_DOCUMENTS_GLOBAL = 'regulatory-fund-documents-global',
  INVESTOR_DOCUMENTS = 'investor-documents',
}
// TODO rename as LiteratureDocumentDataDTO as this describes data from external api
export interface LiteratureDocumentData {
  id?: string | null;
  href?: string | null;
  cmsSearchTitle?: string | null;
  documentId?: string | null;
  dctermsTitle?: string | null;
  dctermsDescription?: string | null;
  availableForLSFEmailOrder?: string;
  dctermsAudience?: string[] | null;
  dctermsType?: LiteratureDocType | null;
  frkFundGroupCode?: string | null;
  // comma separated list of ShareClassCode ids eg "A, R, C, I, IS"
  frkShareClassCode?: string | null;
  frkShareClassFeeStructure?: string | null;
  // comma separated list of FundIds
  frkOneTISNumber?: string | null;
  literatureCode?: string | null;
  shareClassCode?: string | null;
  fundName?: string | null;
  shareClassName?: string | null;
  fileExtension?: string | null;
  fileSize?: string | null;
  publicationDate?: ISODate | null; // format "YYYY-MM-DD"
  nextUpdateDate?: string | null; // format "MM/YY"
  thumbnailPath?: string | null;
  description?: string | null;
  downloadUrl?: string | null;
  isClientUse?: string | null;
  assetType?: string | null;
  dctermsLanguage?: string[] | null;
  dctermsAvailable?: string | null;
  dctermsValid?: string | null;
  isActive?: string | null;
  packageCode?: string | null;
  exemptedUserTypes?: string | null;
  contentPath?: string | null;
  literatureHref?: string | null;
  frkReferenceDate?: string | null;
  cmsExternalLink?: string | null;
  topic?: string | null;
  subTopic?: string | null;
  isSiteIntl?: boolean;
  finraLink?: string;
  assetCategory?: string;
  isOrdItself?: string;
  isInvestor?: boolean;
  mandatoryMaterial?: string[];
  isInv?: string;
  keywords?: string[];
  litDealerUse?: boolean;
  orderDate?: string;
  blueSkyStates?: string[];
  litPathPrevious?: string;
  customFilePath?: string;
  moreLink?: string;
  languageFilterValues?: string;
  pdfNumberingAppID?: string | null;
  isHistoryData?: boolean;
  dcsourceSystem?: string;
  externalId?: string;
  availableForLoggedInUser?: string;
  docId?: string;
  suppressIndexing?: string;
  fileUploadDate?: string;
  approvedFirm?: string[];
  firmSpecific?: string[];
  approvedFirms?: string[];
  specificTargetSystems?: string[];
}

export interface LiteratureHistoryData {
  litHistDetail?: (LiteratureDocumentData | null)[] | null;
}
