import {
  ADM,
  AnbimaCode,
  APIR,
  ARSN,
  Bloomberg,
  ConfigurationCountry,
  ConfigurationLocale,
  CUSIP,
  DSC,
  Frontend,
  FundId,
  IntradayNAV,
  IOPV,
  Iress,
  IressNAVCode,
  ISIN,
  LowLoad,
  MEXID,
  MLIdentifier,
  NASDAQ,
  ReutersNumber,
  RFI,
  SEDOL,
  ShareClassCode,
  TAID,
  Ticker,
  VLN,
  WKN,
} from '@types';

// TODO: should we create more FundIdentifiers for string types below?
export interface Identifiers {
  adm?: ADM;
  anbimaCode?: AnbimaCode;
  apir?: APIR;
  arsn?: ARSN;
  bloomberg?: Bloomberg;
  countryCode?: ConfigurationCountry;
  cusip?: CUSIP;
  dsc?: DSC;
  frontend?: Frontend;
  fundbmTicker?: Ticker;
  fundId?: FundId;
  intradayNav?: IntradayNAV;
  iopv?: IOPV;
  iress?: Iress;
  iressinavCode?: IressNAVCode;
  isin?: ISIN;
  languageCode?: ConfigurationLocale;
  lowload?: LowLoad;
  mexId?: MEXID;
  mlidentifier?: MLIdentifier;
  nasdaqxnms?: NASDAQ;
  reuterSystem?: ReutersNumber;
  rfi?: RFI;
  sedol?: SEDOL;
  shareClassCode?: ShareClassCode;
  taid?: TAID;
  ticker?: Ticker;
  vln?: VLN;
  wkn?: WKN;
}

export type IdentifierKey = keyof Identifiers;
