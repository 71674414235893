export interface Ratings {
  morningStarCategory?: string;
  morningStarRatingOverall?: number;
  morningStarRating3yr?: number;
  morningStarRating5yr?: number;
  morningStarRating10yr?: number;
  monthEndNoOfFunds3yr?: string;
  monthEndNoOfFunds5yr?: string;
  monthEndNoOfFunds10yr?: string;
  asOfDate?: string;
  morningStar3yrHybrid?: boolean;
  morningStar5yrHybrid?: boolean;
  morningStar10yrHybrid?: boolean;
  morningStarOverallHybrid?: boolean;
  morningStarEqStylebox?: string;
  morningStarFiStylebox?: string;
  morningStarStyleboxType?: string;
  morningStarStyleboxHist?: string;
  morningStarPortfolioDate?: string;
  analizyRatingEffectiveDate?: string;
  analizyRatingCategory?: string;
  analizyRating36Months?: string;
  analizyRating12Months?: string;
}
