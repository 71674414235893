import { Injectable } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { SiteConfigService } from '@services/site-config.service';
import { GlobalConfigService } from '@services/global-config-service';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { CaveatCollection, FundId, FundShareClassId } from '@types';
import { Observable } from 'rxjs';
import { TranslateService } from '@shared/translate/translate.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvConfigService } from '@services/env-config.service';

export interface CaveatValue {
  taxonomy: string;
  env: string;
}

export interface CaveatElasticSkeleton {
  caveats: CaveatCollection;
}

export interface DocumentTypeMapping {
  'ftcore:FundAlert': string;
  'ftcore:CaveatProximal': string;
  'ftcore:CaveatLegal': string;
  'ftcore:CaveatFootnote': string;
}

const CAVEAT_API = '/api/caveat';
const LIVE = 'live';
const DRAFT = 'draft';

@Injectable({
  providedIn: 'root',
})
export class CaveatDataService {
  constructor(
    private appState: AppStateService,
    private globalConfig: GlobalConfigService,
    private http: HttpClient,
    private siteConfig: SiteConfigService,
    private translateService: TranslateService,
    private envConfigService: EnvConfigService
  ) {}

  private static mapAlert(
    alertMap: object = {},
    fund: FundId | FundShareClassId,
    alert,
    ppssMap: {}
  ) {
    if (alertMap[fund] === undefined) {
      alertMap[fund] = [alert];
    } else {
      alertMap[fund].push(alert);
    }
    if (alert.ppssMessage && !ppssMap[fund]) {
      ppssMap[fund] = alert;
    }
  }

  getData$(): Observable<CaveatCollection> {
    let currentLangCode = '';
    return this.globalConfig.getGlobalConfigSubject$().pipe(
      // Ensure distinct emissions based on changes in currentLangCode.
      distinctUntilChanged(
        (prevConfig: any, currentConfig: any) =>
          prevConfig?.common?.taxonomyLanguage ===
          currentConfig?.common?.taxonomyLanguage
      ),
      switchMap(() => {
        // Update currentLangCode on every emission.
        currentLangCode = this.siteConfig.common?.taxonomyLanguage;
        // Return the API call.
        const request: CaveatValue = {
          taxonomy: currentLangCode,
          env: this.appState.getEnvironment(),
        };
        return this.getCaveatData(request)
          .pipe(map((response) => this.processElasticResponse(response)))
          .pipe(
            map((page) => this.applyDynamicLabels(page)),
            map(this.processAlerts)
          );
      })
    );
  }

  private processAlerts(caveatData: CaveatElasticSkeleton): CaveatCollection {
    const caveats = caveatData?.caveats;
    if (caveats) {
      const alertMap = {};
      const ppssMap = {};
      // map fund and share classes to alerts
      caveats.alerts.forEach((alert) => {
        alert.fl.forEach((fund) => {
          CaveatDataService.mapAlert(alertMap, fund, alert, ppssMap);
        });
        alert.fscl.forEach((fundShareClass) => {
          CaveatDataService.mapAlert(alertMap, fundShareClass, alert, ppssMap);
        });
      });
      caveats.alerts = {
        alertMap,
        ppssMap,
      };
      return caveats;
    }
    return {
      footnotes: [],
      legals: [],
      proximals: [],
      alerts: { alertMap: {}, ppssMap: {} },
    };
  }

  private applyDynamicLabels(
    caveatData: CaveatElasticSkeleton
  ): CaveatElasticSkeleton {
    // Loop through caveats type
    const caveats = caveatData?.caveats;
    const labelRegex = /\$LABEL(\(|\[)(\w+(?:.\w+)*)(\)|\])/g;
    for (const caveatsKey in caveats) {
      // Excluding 'alerts' to avoid unnecessary processing
      if (caveatsKey !== 'alerts') {
        // loop through each caveat
        caveats[caveatsKey].forEach((caveat) => {
          const token: RegExpMatchArray = caveat.content?.match(labelRegex);
          if (token) {
            caveat.content = caveat.content?.replace(
              labelRegex,
              (m, g1, g2) => {
                return this.translateService.instant(g2);
              }
            );
          }
        });
      }
    }
    return caveatData;
  }

  /**
   * Processes the response data from an Elasticsearch query and maps it to a structured format.
   */
  public processElasticResponse(data: any): CaveatElasticSkeleton {
    const caveatData = data.results?.response?.hits;
    const caveatElasticResponse: CaveatElasticSkeleton = {
      caveats: {
        alerts: [],
        proximals: [],
        legals: [],
        footnotes: [],
      },
    };

    const documentTypeMapping: DocumentTypeMapping = {
      'ftcore:FundAlert': 'alerts',
      'ftcore:CaveatProximal': 'proximals',
      'ftcore:CaveatLegal': 'legals',
      'ftcore:CaveatFootnote': 'footnotes',
    };
    if (caveatData.hits.length > 0) {
      caveatData?.hits.forEach((hit) => {
        hit._source.content = hit._source.caveatContent;
        const documentType = documentTypeMapping[hit._source.documentType];
        if (documentType) {
          caveatElasticResponse.caveats[documentType].push(hit._source);
        }
      });
    }
    return caveatElasticResponse;
  }

  /**
   * Sends an HTTP POST request to retrieve caveat data based on the specified request parameters.
   */
  public getCaveatData(request: CaveatValue) {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = new URLSearchParams();
    body.set('taxonomy', request.taxonomy);
    body.set('env', request.env);
    let status = LIVE;
    if (this.envConfigService.isContentPreview()) {
      status = DRAFT;
    }
    body.set('status', status);
    return this.http.post(CAVEAT_API, body.toString(), { headers });
  }
}
