import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Page } from '@bloomreach/spa-sdk';
import { getThemeNameFromPath } from '@utils/link-utils';
@Injectable({
  providedIn: 'root',
})
export class PageThemeService {
  constructor(@Inject(DOCUMENT) private documentRef: Document) {}

  /**
   * swaps single page theme if theme defined in page properties
   * @param page page with properties
   */
  public setPageTheme(page: Page): void {
    let newTheme: string = page.getComponent()?.getModels()?.pageData
      ?.pageTheme;

    this.initAltsStyles(page);

    // newTheme will be read directly form page property
    let pageThemeElem: HTMLElement = this.documentRef.getElementById(
      'page-theme'
    );

    // Force frk dee theme
    if (newTheme) {
      newTheme = 'frk-dee';
    }

    const defaultThemeElem = this.getDefaultThemeElem();
    const defaultCssPath = defaultThemeElem?.getAttribute('href');
    const defaultTheme = getThemeNameFromPath(defaultCssPath);

    // if no new page theme or newTheme is the default, then cleanup and exit
    if (!newTheme || defaultTheme === newTheme) {
      // try to remove page theme, just in case it was created on previous page
      if (pageThemeElem) {
        this.documentRef
          .getElementsByTagName('head')[0]
          .removeChild(pageThemeElem);
      }
      return;
    }

    // we replace theme with / and . to avoid scenario when theme name is part of folder name and path
    const themeCssPath = defaultCssPath?.replace(
      '/' + defaultTheme + '.',
      '/' + newTheme.toLocaleLowerCase() + '.'
    );

    // if there is no theme stylesheet on the page, we create one
    // we do this only if replacing theme will be required
    if (!pageThemeElem) {
      pageThemeElem = this.createStylesheetElement(
        defaultThemeElem,
        pageThemeElem
      );
    }

    // no need to replace theme if it is the same
    if (defaultTheme !== newTheme) {
      pageThemeElem.setAttribute('href', themeCssPath);
    }
  }

  /**
   * Init the Alts CSS if needed
   */
  private initAltsStyles(page: Page) {
    const fontelloId = 'fontello';
    const altsThemeId = 'alts-theme';
    const defaultThemeElem = this.getDefaultThemeElem();
    const cssPath = '/assets/alts/css/ft-alternatives.css';
    const fontelloPath = '/assets/alts/fontello/css/fontello.css';

    let includeAltsTheme: string = page.getComponent()?.getModels()?.pageData
      ?.includeAltsCSS;

    if (!includeAltsTheme) {
      const pageDocument = page.getDocument<PageDocument>();
      const layoutName = pageDocument?.model?.data?.contentType;

      if (layoutName !== undefined) {
        layoutName.includes('ftalts:')
          ? (includeAltsTheme = 'true')
          : (includeAltsTheme = undefined);
      }
    }

    // Remove alts stylesheet
    this.removeStylesheet(fontelloId);
    this.removeStylesheet(altsThemeId);

    if (includeAltsTheme) {
      this.creatStylesheetElement(
        defaultThemeElem,
        fontelloId,
        fontelloPath,
        true
      );
      this.creatStylesheetElement(defaultThemeElem, altsThemeId, cssPath, true);
    }
  }

  /**
   * Remove stylesheet by id
   * @param id - id of stylesheet to remove
   */
  private removeStylesheet(id: string) {
    const styleElem: HTMLElement = this.documentRef.getElementById(id);
    if (styleElem) {
      this.documentRef.getElementsByTagName('head')[0].removeChild(styleElem);
    }
  }

  /**
   * Get the default theme element
   * @returns defaultThemeElem
   */
  private getDefaultThemeElem(): HTMLElement {
    const defaultThemeElem: HTMLElement = this.documentRef.getElementById(
      'default-theme'
    );
    return defaultThemeElem;
  }

  /**
   * Create stylesheet and insert after default theme
   * @param defaultThemeElem - element of default theme
   * @param id - the id for the new stylesheet
   * @param path - the path to the css
   * @returns HTMLElement
   */
  private creatStylesheetElement(
    defaultThemeElem: HTMLElement,
    id: string,
    path: string,
    insertBefore = false
  ): HTMLElement {
    const stylesheetEl = this.documentRef.createElement('link');
    stylesheetEl.setAttribute('id', id);
    stylesheetEl.setAttribute('rel', 'stylesheet');
    stylesheetEl.setAttribute('type', 'text/css');
    stylesheetEl.setAttribute('href', path);

    // insert the page css DIRECTLY after the default css
    // this is important to stop other css being defined between themes and creating obscure CSS order bugs
    // see `insertAfter()` note here: https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore#example_2
    const parentNode: HTMLElement = defaultThemeElem.parentElement;
    parentNode.insertBefore(
      stylesheetEl,
      insertBefore ? defaultThemeElem : defaultThemeElem.nextSibling
    );
    return stylesheetEl;
  }

  /**
   * creates new theme stylesheet element with id 'page-theme'
   * @param pageThemeElem existing or empty theme stylesheet dom element
   */
  private createStylesheetElement(
    defaultThemeElem: HTMLElement,
    pageThemeElem: HTMLElement
  ): HTMLElement {
    pageThemeElem = this.documentRef.createElement('link');
    pageThemeElem.setAttribute('id', 'page-theme');
    pageThemeElem.setAttribute('rel', 'stylesheet');
    pageThemeElem.setAttribute('type', 'text/css');

    // insert the page css DIRECTLY after the default css
    // this is important to stop other css being defined between themes and creating obscure CSS order bugs
    // see `insertAfter()` note here: https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore#example_2
    const parentNode: HTMLElement = defaultThemeElem.parentElement;
    parentNode.insertBefore(pageThemeElem, defaultThemeElem.nextSibling);
    return pageThemeElem;
  }
}
