import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('BaseModalComponent');

@Component({
  selector: 'ft-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit {
  @Input() closeOnClickToBackground = true;
  @Input() closeLabel: string;
  @Input() title: string;
  @Input() isGrey = false;
  @Input() showCloseButton = true;
  @Input() headerBottomBorder = true;
  @Input() size = 'normal';

  @Output() modalClosed = new EventEmitter();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if (this.closeOnClickToBackground) {
      const nativeElement = this.element.nativeElement;
      nativeElement
        .getElementsByClassName('modal')[0]
        .addEventListener('click', () => {
          this.close();
        });
      nativeElement
        .getElementsByClassName('modal__contents')[0]
        .addEventListener('click', (e) => {
          e.stopPropagation();
        });
    }
    logger.info('initialized');
  }

  close() {
    this.element.nativeElement.classList.remove('modal--show');
    this.modalClosed.emit('closed');
  }

  show() {
    this.element.nativeElement.classList.add('modal--show');
  }
}
