import { formatCurrency } from '../il8n/currency';
// import {convertUnitsToTimePeriod} from './date-utils';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import each from 'lodash/each';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { appendCurrency, formatPercentage, parseNumber } from './number-utils';
import { formatString } from './string-utils';
import { Logger } from '../logger';

const logger = Logger.getLogger('data-utils');

/**
 * @param shareClassName Series T-USD
 * @param currency e.g. USD
 * @returns Series T (without -USD)
 */
export function formatShareClassName(
  shareClassName: string,
  currency: string
): string {
  if (!shareClassName || !currency) {
    return shareClassName;
  }
  const normalisedShareClassName = shareClassName.replace(
    new RegExp('-' + currency),
    ''
  );
  return normalisedShareClassName;
}

/**
 * This function takes a CUSIP in the form 'G3658U329' and returns it in the format 'G36 58U 329'
 *
 * COREWEB-4599: note this will be non breaking "\xa0" not typical " "
 */
export function formatCusip(cusip: string): string {
  const normalisedCusip = cusip.replace(/ /g, '');
  return normalisedCusip.length === 9
    ? `${normalisedCusip.substring(0, 3)}\xa0${normalisedCusip.substring(
        3,
        6
      )}\xa0${normalisedCusip.substring(6)}`
    : cusip;
}

export const FORMAT_TYPES = {
  /**
   * SPECIAL CASE FOR 2.2 Billion == £ 2.2 Billion
   */
  APPEND_CURRENCY: 'APPEND_CURRENCY',
  /**
   * SPECIAL CASE FOR FIELD WITH MULTIPLE VALUES
   */
  MULTIPLE_VALUES: 'MULTIPLE_VALUES',
  /**
   * SPECIAL CASE FOR CUSIP STRING COMES AS "123456789" NEEDS DISPLAYED AS "123 456 789"
   */
  CUSIP_STRING: 'CUSIP_STRING',
  //
  TIME_PERIOD: 'TIME_PERIOD',
  CURRENCY: 'CURRENCY',
  PERCENTAGE: 'PERCENTAGE',
  STRING: 'STRING',
  CONSTANT: 'CONSTANT',
  FUNCTION: 'FUNCTION',
};

/**
 * extract kebab case properties in order of camel case ids list
 */
export function suppressByIdFromFieldList(
  sourceData: any,
  camelCaseIds: string[]
): any {
  const extractedData = Object.assign({}, sourceData);
  camelCaseIds.forEach((camelCaseId) => {
    const kebabCaseId = kebabCase(camelCaseId);
    // Ignore empty string ids
    if (!kebabCaseId) {
      return;
    }
    const sourceField = sourceData[kebabCaseId];
    if (!sourceField) {
      const camelCaseFields = Object.keys(sourceData).map((o) => camelCase(o));
      logger.error(
        `Unknown property '${camelCaseId}', available properties '${camelCaseFields.join(
          ','
        )}'`
      );
      return;
    }
    delete extractedData[kebabCaseId];
  });
  return extractedData;
}

/**
 * extract kebab case properties in order of camel case ids list
 */
export function extractByIdFromFieldList(
  sourceData: any,
  camelCaseIds: string[]
): any {
  const extractedData = {};
  each(camelCaseIds, (camelCaseId) => {
    const kebabCaseId = kebabCase(camelCaseId);
    // Ignore empty string ids
    if (!kebabCaseId) {
      return;
    }
    const sourceField = sourceData[kebabCaseId];
    if (!sourceField) {
      const camelCaseFields = Object.keys(sourceData).map((o) => camelCase(o));
      logger.error(
        `Unknown property '${camelCaseId}', available properties '${camelCaseFields.join(
          ','
        )}'`
      );
      return;
    }
    extractedData[kebabCaseId] = sourceField;
  });
  return extractedData;
}

/**
 * @param fieldConfig description of data to get {'label': {'dataKey':'a.b.c', 'type': 'currency'}}
 * @param currency eg USD
 * @returns id/val array pair
 */
export function extractFormattedValue(
  data: any,
  labelKey: string,
  fieldConfig: any,
  currency: string
): any {
  let dataObj: any = {
    id: kebabCase(labelKey),
  };
  if (fieldConfig.type === FORMAT_TYPES.MULTIPLE_VALUES) {
    let isValid = false;
    // COREWEB-3454 COREWEB-3646 COREWEB-3518 COREWEB-3677: Magic Business Rules around primary fields
    // is a sub field list has a primary
    // collapse if primary not valid or all non primary not valid
    const primaryFieldId = fieldConfig.primaryField;
    let primaryField = null;
    let hasNonPrimaryFields = false;
    dataObj.values = [];
    each(fieldConfig.subFields, (subFieldConfig, subFieldId) => {
      const subFieldData = extractFormattedValue(
        data,
        subFieldId,
        subFieldConfig,
        currency
      );
      const subFieldLevelKeepUnavailableValue = subFieldConfig.hasOwnProperty(
        'keepUnavailableValue'
      )
        ? subFieldConfig.keepUnavailableValue
        : true;
      if (subFieldData.isValid || subFieldLevelKeepUnavailableValue) {
        dataObj.values.push(subFieldData);
      }
      if (subFieldData.isValid) {
        isValid = true;
        if (primaryFieldId !== subFieldId) {
          hasNonPrimaryFields = true;
        }
      }
      if (primaryFieldId === subFieldId) {
        primaryField = subFieldData;
      }
    });
    dataObj.isValid = isValid;
    // collapse if primary is not valid, or if only valid field
    if (primaryFieldId && (!hasNonPrimaryFields || !primaryField.isValid)) {
      dataObj = primaryField;
      primaryField.id = kebabCase(labelKey);
    }
  } else if (fieldConfig.type === FORMAT_TYPES.FUNCTION) {
    // FIMES-77 adding swiss army knife parsing
    const value = fieldConfig.dataFunc(data, labelKey, fieldConfig, currency);
    const isValid = !!value && value !== '—';
    dataObj.val = isValid ? value : '—';
    dataObj.isValid = isValid;
  } else {
    let isValid = false;
    let value = get(data, fieldConfig.dataKey, null);
    // COREWEB-4987: optional backup data keys, some times data is not in consistent places...
    // perhaps there's a controlable release that updates variable names
    // this handles that situation
    if (value === null && fieldConfig.backupDataKey) {
      value = get(data, fieldConfig.backupDataKey, null);
    }
    switch (fieldConfig.type) {
      case FORMAT_TYPES.APPEND_CURRENCY:
        value = appendCurrency(value, currency);
        isValid = value !== '—';
        break;
      // case FORMAT_TYPES.TIME_PERIOD:
      //   value = convertUnitsToTimePeriod(value, fieldConfig.timePeriodUnits);
      //   isValid = value !== '—';
      //   break;
      case FORMAT_TYPES.CURRENCY:
        value = formatCurrency(value, currency);
        isValid = value !== '—';
        break;
      case FORMAT_TYPES.PERCENTAGE:
        value = formatPercentage(value);
        isValid = value !== '—';
        break;
      case FORMAT_TYPES.STRING:
        value = formatString(value);
        isValid = value !== '—';
        break;
      case FORMAT_TYPES.CUSIP_STRING:
        value = formatString(value);
        isValid = value !== '—';
        if (isValid) {
          value = formatCusip(value);
        }
        break;
      case FORMAT_TYPES.CONSTANT:
        value = fieldConfig.dataKey;
        isValid = true;
        break;
      default:
        isValid = true;
    }
    dataObj.val = isValid ? value : '—';
    dataObj.isValid = isValid;
  }
  if (fieldConfig.addendumData) {
    for (const addendumAccessKey in fieldConfig.addendumData) {
      if (fieldConfig.addendumData.hasOwnProperty(addendumAccessKey)) {
        const addendumDataConfig = fieldConfig.addendumData[addendumAccessKey];
        const addendumData = extractFormattedValue(
          data,
          addendumAccessKey,
          addendumDataConfig,
          currency
        );
        const addendumDataLevelKeepUnavailableValue = addendumDataConfig.hasOwnProperty(
          'keepUnavailableValue'
        )
          ? addendumDataConfig.keepUnavailableValue
          : true;
        if (addendumData.isValid || addendumDataLevelKeepUnavailableValue) {
          dataObj[addendumAccessKey] = addendumData;
        }
      }
    }
  }
  return dataObj;
}

/**
 * @param valueMap list of data keys to get {'label': {'dataKey':'a.b.c', 'type': 'currency'}}
 * @param currency eg USD
 * @param functionLevelKeepUnavailableValue eg if true unavailable value will be replaced with mdash
 * @returns id/val object
 */
export function extractFormattedValues(
  data: any,
  valueMap: any,
  currency: string,
  functionLevelKeepUnavailableValue = false
): any[] {
  const validData = [];
  for (const labelKey in valueMap) {
    if (valueMap.hasOwnProperty(labelKey)) {
      const fieldConfig = valueMap[labelKey];
      // optional custom suppress entire field function
      if (
        fieldConfig.suppressionFunc &&
        fieldConfig.suppressionFunc(data, fieldConfig)
      ) {
        continue;
      }
      const value = extractFormattedValue(
        data,
        labelKey,
        fieldConfig,
        currency
      );
      // COREWEB-5222, COREWEB-5657- checks if value is 'N/A'
      value.isNA =
        get(value, 'val') === 'N/A' ||
        (get(value, 'values[0].val') === 'N/A' &&
          get(value, 'values[1].val') === 'N/A');
      // optional custom "Is Valid" function
      if (fieldConfig.isValidFunc) {
        value.isValid = fieldConfig.isValidFunc(data, fieldConfig, value);
      }
      // optional custom "Is Dash" function COREWEB-5726
      if (fieldConfig.isDashFunc) {
        value.isDash = fieldConfig.isDashFunc(data);
        if (value.isDash) {
          value.val = '—';
          value.asOf = null;
        }
      }
      // defaults to not removing non valid items in signature, overridable at field level
      let keepUnavailableValue = functionLevelKeepUnavailableValue;
      if (fieldConfig.hasOwnProperty('keepUnavailableValue')) {
        keepUnavailableValue = fieldConfig.keepUnavailableValue;
      }
      if (value.isValid || keepUnavailableValue) {
        validData.push(value);
      }
    }
  }
  return validData;
}

/**
 * quick version of lodash get + formatString
 *
 * - supports multiple paths
 * - supports '-' being counted as invalid
 *
 * @param path or paths to check sequentially for value
 */
export function getOrEmdash(container, path: string | string[]): string {
  let value;
  if (Array.isArray(path)) {
    const paths = path;
    for (path of paths) {
      value = get(container, path);
      if (value && value !== '-') {
        return value;
      }
    }
    return '—';
  }
  value = get(container, path);
  if (!value || value === '-') {
    value = '—';
  }
  return value;
}

/**
 * // TODO: look at merging getDirectionClass / navChangeClass / navDirection
 */
export function navDirection(val?: any): string {
  let parsed = val ? val.replace(/[^\d.-]+/g, '') : '';
  parsed = parseNumber(parsed);
  if (isNull(parsed)) {
    return '';
  }
  if (parsed > 0) {
    return 'GAIN';
  }
  if (parsed < 0) {
    return 'LOSS';
  }
  return 'STATIC';
}

/**
 * // TODO: look at merging getDirectionClass / navChangeClass / navDirection
 */
export function navChangeClass(direction: string): string {
  switch (direction) {
    case 'GAIN':
      return 'gain';
    case 'LOSS':
      return 'loss';
    case 'STATIC':
      return 'static';
    default:
      return '';
  }
}

/**
 * // TODO: look at merging getDirectionClass / navChangeClass / navDirection
 */
export function getDirectionClass(directionVal: string | number): string {
  if (directionVal === undefined) {
    return 'nodata';
  }
  const dlyNavChange = parseNumber(directionVal);
  if (dlyNavChange > 0) {
    return 'gain';
  } else if (dlyNavChange < 0) {
    return 'loss';
  }
  // else (dlyNavChange === 0) {
  return 'static';
}

export function mutableReplaceArray(mutableArray: any[], newArray: any[]) {
  while (mutableArray.length > 0) {
    mutableArray.shift();
  }
  newArray.forEach((row) => {
    mutableArray.push(row);
  });
}

/**
 * Quicker implementation of node_modules\lodash\_baseGet.js
 * removed castPath / toKey to speed up 'get'
 *
 * harder to use but much faster x4 (especially useful on IE)
 *
 * useful for PPSS where 100k's of get calls being made
 *
 * SPEED TEST
 *
 * let afilterStartTime = Date.now();
 * for (let i = 0; i < 1000000; i++) {
 *    get(rawPpssData, 'globalProductsValue.ppsListIntl[0].navAsOfDate.val', '');
 *  }
 * let afilterEndTime = Date.now();
 * console.log(' - 1,000,000 get took', afilterEndTime - afilterStartTime);
 *
 * let bfilterStartTime = Date.now();
 * for (let i = 0; i < 1000000; i++) {
 *    fastGet(rawPpssData, ['globalProductsValue', 'ppsListIntl', 0, 'navAsOfDate', 'val'], '');
 *  }
 * let bfilterEndTime = Date.now();
 * console.log(' - 1,000,000 fastGet took', bfilterEndTime - bfilterStartTime);
 *
 * Chrome stats
 *  - 1,000,000 get took 399
 *  - 1,000,000 fastGet took 90
 *
 * IE stats
 *  - 1,000,000 get took 8433
 *  - 1,000,000 fastGet took 1847
 *
 * Regex Replacements
 * get\((.*), '(.*)' => fastGet($1, ['$2']
 */
export function fastGet(object: any, path: string[], defaultValue: any): any {
  if (!object) {
    return defaultValue;
  }
  let index = 0;
  const length = path.length;
  while (object !== undefined && index < length) {
    object = object[path[index++]];
  }
  if (object === undefined || object === '' || object === '-') {
    return defaultValue;
  }
  return object;
}

/**
 * creates a custom {@see lodash/get} equivalent based off
 * list of column headers
 *
 * @returns function params object, path, defaultValue same api as lodash/get
 */
export function dataGridGetGenerator(keyList: string[]) {
  const cachedIndexesHashmap = {};
  each(keyList, (key, keyIndex) => {
    cachedIndexesHashmap[key] = keyIndex;
  });
  /**
   * same api as lodash/get
   * @param {Object} arrayObject
   * @param {String} path
   * @param {*} [defaultValue]
   * @return {*}
   */
  return (arrayObject, path, defaultValue = null) => {
    const fullPathIndex = cachedIndexesHashmap[path];
    if (fullPathIndex === undefined) {
      // logger.info('Unknown Path : ' + path);
      // speed up future mis-hits
      cachedIndexesHashmap[path] = defaultValue;
      return defaultValue;
    }
    const value = arrayObject[fullPathIndex];
    if (value === undefined || value === '' || value === '-') {
      return defaultValue;
    }
    return value;
  };
}

/**
 * requirement is to have 2 column layout with order e.g [[1, 4], [2, 5], [3, 6]]
 */
export function reorderLinksForTwoColLayout(links) {
  const secondHalfIndex = Math.ceil(links.length / 2);
  const insertions = links.splice(
    secondHalfIndex,
    links.length - secondHalfIndex
  );
  for (let i = 0; i < insertions.length; i++) {
    links.splice(i * 2 + 1, 0, insertions[i]);
  }
}

/**
 * split fundShareClassCode id in separate chunks - note that Canada has -CAD or -USD as part of shareclass
 */
export function getFundIdAndShareclassCode(
  fundShareClassCode: string
): string[] {
  const fundId = fundShareClassCode.substring(
    0,
    fundShareClassCode.indexOf('-')
  );
  const shareClassCode = fundShareClassCode.substring(
    fundShareClassCode.indexOf('-') + 1
  );
  return [fundId, shareClassCode];
}
