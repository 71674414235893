/**
 * This file declares all of the common Bloomreach
 * Documents and Compounds
 */

import { CardFundInfo, IconType } from '@frk/eds-components';
import { Reference } from '@bloomreach/spa-sdk';
/**
 * Interface for the Banner Content
 */
export interface BannerContent {
  isCondensed?: boolean;
  image?: Reference;
  imageSrc?: string;
  logoLink?: string;
  altlogoText?: string;
  category?: string;
  title?: string;
  subTitle?: string;
  summary?: string;
  button?: any;
  video?: Video;
  importantinformationlink?: Link;
  name?: string;
  mobile?: string;
  smallTablet?: string;
  largeTablet?: string;
  smallDesktop?: string;
  regularDesktop?: string;
  introduction?: string;
  titleSize?: string;
}

export interface Video {
  accountId?: string;
  videoId?: string;
  videoPlayerId?: string;
  playerId?: string;
  videoTranscript?: string;
  mute?: boolean;
  hideControls?: boolean;
}

/**
 * Interface for the Page Section Content
 */
export interface PageSectionContent {
  sectionId?: string;
  image?: any;
  imageSrc?: string;
  preTitle?: string;
  title?: string;
  subTitle?: string;
  summary?: string;
  button?: any;
  cards?: Array<CardContent | FundCardContent>;
  link?: Link;
  isContentLeft?: boolean;
  imageCompound?: WidenAsset;
  secondaryTheme?: string;
  downloadLocation?: string;
  footnote?: {
    value: string;
  };
}

/**
 * Card Compound Content
 */
export interface CardContent {
  name?: string;
  displayName?: string;
  preTitle?: string;
  body?: string;
  bodyContent?: string;
  title: string;
  isIcon?: boolean;
  content?: string;
  image?: WidenAsset;
  // TODO: why does this interface need both imageSrc and imgSrc properties?
  imageSrc?: string;
  imgSrc?: string;
  text?: string;
  href?: string;
  link?: Link;
  linkCompound?: Link;
  linkText?: string;
  ctaText?: string;
  url?: string;
  target?: string;
  external?: boolean;
  externalSuffix?: string;
  externalLink?: boolean;
  isNewWindow?: string;
  signInLink?: boolean;
  ctaTheme?: 'primary' | 'secondary' | 'secondary-dark' | 'tertiary';
  signInRequired?: boolean;
  downloadLocation?: string;
  docTypeSrc?: string;
  eventId?: string;
  eventParams?: {
    file_name: string;
    link_url: string;
    file_extension: string;
    document_source?: string;
    document_title?: string;
    embed_id?: string;
  };
  widenParams?: WidenDocumentJson;
  theme?: string;
}

/**
 * Fund Card Content
 */
export interface FundCardContent {
  title?: string;
  content?: string;
  fundShareClass: string;
  subTitle?: string;
  btnLabel?: string;
  href?: string;
  cardInstance?: CardFundInfo[];
}
/**
 * Widen Asset Compound
 */
export interface WidenAsset {
  name?: string;
  displayName?: string;
  widenAsset?: string;
  widenDocument?: string;
  image?: string;
  altText?: string;
}

/**
 * Widen Document Compound
 */
export interface WidenDocument {
  name: string;
  displayName: string;
  widenDocument: string;
}

export interface WidenDocumentJson {
  id: string[];
  url: string;
  external_id?: string;
  document_title?: string;
  document_source?: string;
  last_update_date?: string;
  noindex?: boolean;
}
/**
 * Link Compound
 */
export interface Link {
  literatureCode?: string;
  name?: string;
  displayName?: string;
  document?: string | any;
  external?: boolean;
  externalSuffix?: string;
  url: string;
  theme?: string;
  title: string;
  displayText: string;
  linkText?: string;
  documentOrAssetLink?: string;
  downloadLocation?: string;
  target: string;
  targeting?: string;
  date?: string | number;
  widenAssetCompound?: WidenDocument;
  linkCollection?: Array<LinkCollection>;
  signInLink?: boolean;
  signInIntroTextLabel?: string;
  signInContentTextLabel?: string;
  signInRequired?: boolean;
  hideLockIcon?: boolean;
  href?: string;
  signInTooltip?: string;
  isLoggedIn?: boolean;
  isTargeteAvailable?: boolean;
  enableDownloadTracking?: any;
  enableImpressionTracking?: any;
  iconType?: string;
  externalLink?: string;
}

/**
 * Button Collection
 */
export interface ButtonBlock {
  href: string;
  target?: string;
  external?: boolean;
  buttonStyle?: string;
  ctaText: string;
  linkCompound?: Link;
  signInRequired?: boolean;
  externalSuffix?: string;
  noindex?: boolean;
  dataDocId?: string;
  dataTitle?: string;
  externalId?: string;
}
export interface ButtonCollection {
  buttons?: Array<ButtonBlock>;
}

/**
 * Link Collection
 */
export interface LinkCollection {
  name: string;
  displayName: string;
  document: string;
  external: boolean;
  externalSuffix?: string;
  url: string;
  theme: string;
  title: string;
  displayText: string;
  documentOrAssetLink: string;
  target: string;
  widenAssetCompound: WidenDocument;
  signInLink?: boolean;
  signInIntroTextLabel?: string;
  signInContentTextLabel?: string;
  iconType?: IconType;
  downloadLocation?: string;
  targeting?: string;
  widenParameters?: WidenDocumentJson;
  externalLink?: string;
  href?: string;
}

/**
 * type for breadcrumbs as the come from Bloomreach
 * This has been added here as I can't see it already defined elsewhere
 */
export interface BrBreadcrumb {
  title: string;
  url: string;
}

export interface VideoContentBlocks {
  videotitle: string;
  summary: {
    value: string;
  };
  videoscript: string;
  link: Link;
  externalLinkClass: string;
}

export interface VideoTranscriptDetails {
  id: string;
  title: string;
  content: string;
}

export interface CardLinkClickEvent {
  event?: PointerEvent;
  href?: string;
  downloadLocation?: string;
}
